import { DialogContent } from '@mui/material';
import { FileMetaData } from './ImageUploadField';
import ImageUploadLoader from './ImageUploadLoader';
import ImageUploadPreview from './ImageUploadPreview';

const ImageUploadDialogContent = ({
  files,
  filesMetaData,
  loading,
  compressedFile,
  onUploadComplete,
  onRemove,
  setActiveImage,
}: {
  files: string[];
  filesMetaData: FileMetaData[];
  loading: boolean;
  compressedFile: File | null;
  onUploadComplete: (url: string) => void;
  onRemove: (index: number) => void;
  setActiveImage: (activeImage: string) => void;
}) => {
  return (
    <DialogContent sx={(theme) => ({ paddingY: theme.spacing(3) })}>
      {files.map((file, index) => {
        return (
          <ImageUploadPreview
            key={`${file}-${index}`}
            fileSRC={file}
            fileMetaData={filesMetaData[index]}
            onRemove={() => onRemove(index)}
            onClick={setActiveImage}
          />
        );
      })}
      {loading && compressedFile && <ImageUploadLoader rawFile={compressedFile} onComplete={onUploadComplete} />}
    </DialogContent>
  );
};

export default ImageUploadDialogContent;
