import { Button, Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import { MdDownload } from 'react-icons/md';
import useFileCompression from 'hooks/useFileCompression';
import ImageUploadDialog from './ImageUploadDialog';

const IMAGE_UPLOADER_SIZE = 40;

export interface FileMetaData {
  name?: string;
  size?: string;
}

const ImageUploadField = ({
  files = [],
  isHorizontal = false,
  onChange,
}: {
  files?: string[];
  isHorizontal?: boolean;
  onChange: (urls: string[]) => void;
}) => {
  const hasUploadFile = files && files?.length > 0;
  const [filesMetaData, setFilesMetaData] = useState<FileMetaData[]>([]);
  const [open, setOpen] = useState(false);
  const [compressedFile, compressFile] = useFileCompression();
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (event: any) => {
    if (!event.target.files || !event.target.files.length) {
      compressFile(null);
      return;
    }
    setLoading(true);
    compressFile(event.target.files[0]);
    setFilesMetaData([...filesMetaData, { name: event.target.files[0].name, size: event.target.files[0].size }]);
  };

  const handleUploadComplete = useCallback(
    (url: string) => {
      setLoading(false);
      onChange([...files, url]);
      compressFile(null);
    },
    [files, onChange, compressFile],
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = (index: number) => {
    onChange([...files.filter((_, srcIndex) => srcIndex !== index)]);
    setFilesMetaData([...filesMetaData.filter((_, uploadedFilesIndex) => uploadedFilesIndex !== index)]);
  };

  return (
    <>
      {isHorizontal ? (
        <Button
          color="primary"
          variant={'outlined'}
          sx={(theme) => ({
            width: '39px',
            height: '39px',
            padding: hasUploadFile ? 0 : theme.spacing(1),
          })}
          onClick={handleOpen}
        >
          {hasUploadFile ? (
            <img
              src={files[0]}
              alt=""
              width={IMAGE_UPLOADER_SIZE}
              height={IMAGE_UPLOADER_SIZE}
              style={{ borderRadius: 8 }}
            />
          ) : (
            <MdDownload size={16} />
          )}
        </Button>
      ) : (
        <Stack direction={'row'} gap={1} flexWrap={'wrap'}>
          <Button
            variant="outlined"
            onClick={handleOpen}
            startIcon={<MdDownload size={20} />}
            sx={{ width: 'fit-content' }}
          >
            {files?.length && files?.length > 0 ? 'Edit files' : 'Add file'}
          </Button>
          {files?.map((file) => {
            return (
              <img
                src={file}
                alt=""
                width={IMAGE_UPLOADER_SIZE}
                height={IMAGE_UPLOADER_SIZE}
                style={{ borderRadius: 8, minWidth: IMAGE_UPLOADER_SIZE }}
              />
            );
          })}
        </Stack>
      )}
      <ImageUploadDialog
        files={files}
        filesMetaData={filesMetaData}
        open={open}
        loading={loading}
        compressedFile={compressedFile}
        onRemove={handleRemove}
        onClose={handleClose}
        onFileUpload={handleFileUpload}
        onUploadComplete={handleUploadComplete}
      />
    </>
  );
};

export default ImageUploadField;
