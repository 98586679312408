import dayjs, { Dayjs } from 'dayjs';
import { createContext, ReactNode, useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { FaBox } from 'react-icons/fa';
import { LiaPalletSolid } from 'react-icons/lia';
import useBuyersMarketStateStore from 'store/useBuyersMarketStateStore';
import { CombinedError } from 'urql';
import { getIsWeekend } from 'screens/BuyersMarket/LiveMarketLogic';
import ErrorSnackbar from 'components/ErrorSnackbar';
import getMarketisOpen from 'components/LiveMarketBanner/getMarketisOpen';
import { useWhiteLabelling } from 'components/WhiteLabellingProvider';
import { DEFAULT_PAGE_NUMBER } from 'utils/constants';
import {
  BuyersMarketResultsQuery,
  Card,
  Market,
  MarketFilters,
  MarketInputNew,
  MarketMetric,
  MarketSectionResults,
  PageInfo,
  ShippingOption,
  Trucks,
  useBuyersMarketInfoQuery,
  useBuyersMarketResultsQuery,
} from 'generated/graphql';
import { Permission, Scope, useAccess } from './useAccess';
import { useStitchBuyerMarketResults } from './useStitchBuyersMarketResult';
import { useSyncedMutableOfflineState } from './useSyncedMutableOfflineState';
import useUser from './useUser';

export const getUniqueProductId = (sellerId: string, productId: string) => {
  return `${sellerId}-${productId}`;
};
interface BuyersMarketContextProps {
  date: Dayjs;
  fetching: boolean;
  filters?: MarketFilters;
  infoFetching: boolean;
  isMarketQueryPaused: boolean;
  marketError?: CombinedError;
  marketInput?: MarketInputNew;
  metric: MarketMetric;
  pageInfo?: Partial<PageInfo>;
  promotions?: Card[];
  resultsFetching: boolean;
  sections?: MarketSectionResults[];
  shippingOptions?: ShippingOption[];
  breakingPointId: string;
  trucks?: Trucks;
  loadingMarket: boolean;
  onIncreasePage: () => void;
  onResetPage: () => void;
  refresh: () => void;
  refetchResults: () => void;
  setDate: (date: Dayjs) => void;
  setMarketInput: (input: Partial<MarketInputNew>) => void;
  setMetric: (metric: MarketMetric) => void;
}

export const MARKET_METRIC_OPTIONS = [
  { value: MarketMetric.Pallets, icon: <LiaPalletSolid size={16} /> },
  { value: MarketMetric.Units, icon: <FaBox size={16} /> },
];

const Context = createContext<BuyersMarketContextProps | undefined>(undefined);
const DEFAULT_PAGE_SIZE = 50;
const BREAKING_POINT = 20;
const FIVE_MINUTES = 1000 * 60 * 5;
const STORAGE_KEY = 'loadTime';

export const BuyersMarketProvider = ({ children }: { children: ReactNode }) => {
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const isMarketOpen = getMarketisOpen();
  const isOpeningTomorrow = !getIsWeekend(dayjs().add(1, 'day'));
  const [date, setDate] = useState(
    isMarketOpen
      ? dayjs().add(1, 'day')
      : isOpeningTomorrow
        ? dayjs().add(2, 'day')
        : dayjs().add(8 - dayjs().day(), 'days'),
  );
  const [hasError, setHasError] = useState(false);
  const [loadingMarket, setLoadingMarket] = useState(false);
  const marketStateStore = useBuyersMarketStateStore();
  const has = useAccess();
  const { isSeller, user, isPendingReview, isRegistering } = useUser();
  const { themeVariant } = useWhiteLabelling();
  const [theme, setTheme] = useState(themeVariant);

  const hasNoMarketInputs = !marketStateStore.input || !marketStateStore.input?.method;
  const isMarketQueryPaused =
    hasNoMarketInputs ||
    isSeller ||
    isPendingReview ||
    isRegistering ||
    !user?.profileId ||
    !has(Permission.Market, Scope.Read);

  useEffect(() => {
    if (pageNumber !== marketStateStore.input?.paginationOption.pageNumber) {
      marketStateStore.setInput({
        paginationOption: {
          pageNumber: pageNumber,
          pageSize: DEFAULT_PAGE_SIZE,
        },
      });
    }
  }, [pageNumber, marketStateStore]);

  /** Buyers market data - products, pageInfo */
  const [marketResult, handleResfreshMarketResult] = useBuyersMarketResultsQuery({
    variables: {
      input: {
        ...marketStateStore.input!,
        paginationOption: { pageNumber: pageNumber, pageSize: DEFAULT_PAGE_SIZE },
      },
    },
    pause: isMarketQueryPaused,
  });

  const pageInfo = useMemo(() => marketResult.data?.market?.results.page, [marketResult.data]);
  const { marketQuery, stitching, setMarketQuery } = useStitchBuyerMarketResults(
    marketResult.data as BuyersMarketResultsQuery,
  );

  const resultsFetching = marketResult.fetching || marketResult.stale || stitching;

  const productIds = marketQuery?.market.results.sections?.flatMap((section) =>
    section.sellers.flatMap((seller) =>
      seller.types.flatMap((type) =>
        type.groups.flatMap((group) =>
          group.products.map((product) => ({ productId: product.id, sellerId: product.seller.id })),
        ),
      ),
    ),
  );

  const productInfo = productIds?.[productIds.length - BREAKING_POINT];
  const breakingPointId = productInfo ? getUniqueProductId(productInfo.sellerId, productInfo.productId) : '0';

  const handleResetResults = useCallback(() => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    localStorage.setItem(STORAGE_KEY, dayjs().toISOString());
  }, []);

  const validateRefetch = () => {
    const previousLoadTime = localStorage.getItem(STORAGE_KEY);
    if (previousLoadTime && dayjs().diff(dayjs(previousLoadTime), 'milliseconds') > FIVE_MINUTES) {
      if (pageNumber === DEFAULT_PAGE_NUMBER) {
        handleResfreshMarketResult();
      } else {
        setPageNumber(DEFAULT_PAGE_NUMBER);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      localStorage.setItem(STORAGE_KEY, dayjs().toISOString());
    }
  };

  document.addEventListener('visibilitychange', function () {
    if (!document.hidden) {
      validateRefetch();
    }
  });

  window.addEventListener('focus', function () {
    validateRefetch();
  });

  useEffect(() => {
    if (!resultsFetching && loadingMarket) {
      setLoadingMarket(false);
    }
  }, [resultsFetching, loadingMarket]);

  useEffect(() => {
    handleResetResults();
  }, [
    marketStateStore.input?.filters,
    marketStateStore.input?.method,
    marketStateStore.input?.search,
    marketStateStore.input?.truckTypeUUID,
    marketStateStore.input?.buyerID,
    marketStateStore.input?.groupOption,
    marketStateStore.input?.metric,
    handleResetResults,
  ]);

  /** Buyers market info - filters, promotions, shipping methods */
  const [marketInfoResult, handleResfreshMarketInfo] = useBuyersMarketInfoQuery({
    variables: {
      input: {
        ...marketStateStore.input!,
        paginationOption: { pageNumber: 1, pageSize: 25 },
      },
    },
    pause: isMarketQueryPaused,
  });
  const infoFetching = marketInfoResult.fetching;

  /** Only store the state in localstorage of additional info, not paginated results */
  const [marketInfo, , resetValue] = useSyncedMutableOfflineState(marketInfoResult.data?.market as Market | undefined, [
    'buyersMarket',
    marketStateStore.input,
  ]);

  const clearError = () => setHasError(false);

  const handleRefresh = useCallback(() => {
    handleResfreshMarketInfo();
    handleResfreshMarketResult();
  }, [handleResfreshMarketInfo, handleResfreshMarketResult]);

  useEffect(() => {
    if (theme !== themeVariant) {
      setTheme(themeVariant);
      setMarketQuery(undefined); // set market results to undefined
      resetValue(); // reset marketInfo values
      handleResetResults(); // scroll to top and page number to default
      handleRefresh(); // refetch market results and market info query
    }
  }, [themeVariant, handleRefresh, handleResetResults, setMarketQuery, theme, resetValue]);

  const handleSetMarketInput = useCallback(
    (input: Partial<MarketInputNew>) => {
      if (!marketStateStore.input) {
        return;
      }
      setLoadingMarket(true);
      marketStateStore.setInput(input);
    },
    [marketStateStore],
  );
  const value = useMemo(
    () => ({
      date,
      fetching: infoFetching || resultsFetching,
      filters: marketInfo?.filters,
      infoFetching,
      isMarketQueryPaused,
      marketError: marketInfoResult.error || marketResult.error,
      marketInput: marketStateStore.input,
      metric: marketStateStore.input?.metric ?? MarketMetric.Pallets,
      pageInfo,
      promotions: marketInfo?.promotions,
      resultsFetching,
      sections: marketQuery?.market?.results?.sections as MarketSectionResults[],
      shippingOptions: marketInfo?.shipping?.methods,
      breakingPointId,
      trucks: marketInfo?.shipping?.trucks,
      loadingMarket,
      onIncreasePage: () => setPageNumber((prev) => prev + 1),
      onResetPage: () => setPageNumber(DEFAULT_PAGE_NUMBER),
      refresh: handleRefresh,
      refetchResults: handleResfreshMarketResult,
      setDate,
      setMarketInput: (input: Partial<MarketInputNew>) => handleSetMarketInput(input),
      setMetric: (value: MarketMetric) => handleSetMarketInput({ metric: value }),
      // When metric is removed from marketInputNew, convert to using metaDataStateStore from marketStateStore
    }),
    [
      date,
      infoFetching,
      resultsFetching,
      marketInfo,
      breakingPointId,
      handleRefresh,
      marketQuery?.market.results.sections,
      marketInfoResult.error,
      marketResult.error,
      handleResfreshMarketResult,
      marketStateStore,
      pageInfo,
      isMarketQueryPaused,
      loadingMarket,
      handleSetMarketInput,
    ],
  );

  return (
    <Context.Provider value={value}>
      {children}
      <ErrorSnackbar isOpen={!!hasError} onClose={clearError}>
        Error
      </ErrorSnackbar>
    </Context.Provider>
  );
};

export const useBuyersMarket = () => {
  const result = useContext(Context);
  if (!result) throw new Error('useBuyersMarket must be used within a BuyersMarketProvider');
  return result;
};
