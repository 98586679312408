import { StrictMode } from 'react';
import Providers from './Providers';
import Router from './Router';

const App = () => {
  return (
    <StrictMode>
      <Providers>
        <Router />
      </Providers>
    </StrictMode>
  );
};

export default App;
