import { init, browserTracingIntegration } from '@sentry/react';
import 'proxy-polyfill';
import ReactDOM from 'react-dom/client';
import ReactPixel from 'react-facebook-pixel';
import { clearStore } from 'services/idb-store';
import { logError } from 'services/logging';
import 'typeface-nunito';
import 'typeface-roboto';
import 'typeface-roboto-mono';
import { registerSW } from 'virtual:pwa-register';
import { isProd } from 'config/env';
import App from './App';
import './index.css';

if (isProd) {
  const SENTRY_DSN = 'https://c878a8a16b0b40888c12b0cbb06ff04e@o4504723873071104.ingest.sentry.io/4504723878248448';
  init({
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'Failed to fetch dynamically imported module',
      'Firebase: Error (auth/network-request-failed)',
    ],
    dsn: SENTRY_DSN,
    environment: 'production',
    maxBreadcrumbs: 50,
    integrations: [browserTracingIntegration()],
    tracesSampleRate: 0.5,
  });

  const FACEBOOK_TRACKING_PIXEL = '1119188959501360';
  ReactPixel.init(FACEBOOK_TRACKING_PIXEL);
}

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(<App />);

const ONE_HOUR = 60 * 60 * 1000;

try {
  registerSW({
    immediate: true,
    onRegistered(registration) {
      if (registration) {
        // Clear IndexedDB cache on new service worker registration to avoid oudated queries from being used.
        clearStore();
        setInterval(() => {
          if (!(!registration.installing && navigator)) return;
          if ('connection' in navigator && !navigator.onLine) return;
          registration.update().catch((error) => logError(error));
        }, ONE_HOUR);
      }
    },
  });
} catch (err: any) {
  logError(new Error(`Service worker registration failed. Error: ${err.message}`));
}
