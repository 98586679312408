import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';

const ERROR_TYPE_PREFIX_PATTERN = /^\[.+\]:?\s/;
const SEVERE_ERROR_MESSAGE = 'something went wrong';

const ErrorSnackbar = ({ isOpen, children, onClose }: { children?: string; isOpen: boolean; onClose: () => void }) => {
  const formattedMessage = children?.replace(ERROR_TYPE_PREFIX_PATTERN, '');
  const isSevereError = children?.toLowerCase().includes(SEVERE_ERROR_MESSAGE);
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={4000}
      onClose={onClose}
      sx={{
        zIndex: 1500, // Slightly increase zIndex to make sure the error displays above any other snackbars
      }}
    >
      <Alert severity={isSevereError ? 'error' : 'warning'} onClose={onClose}>
        {formattedMessage}
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
