import dayjs, { Dayjs } from 'dayjs';
import getMarketisOpen, {
  MARKET_AFTERNOON_END,
  MARKET_AFTERNOON_START,
  MARKET_MORNING_END,
  MARKET_MORNING_START,
} from 'components/LiveMarketBanner/getMarketisOpen';

const SUNDAY_DAY_INDEX = 0;
const SATERDAY_DAY_INDEX = 6;
const DEFAULT_TIME = '00:00:00';
export enum NextMarketInfoType {
  NextMarketStartTime = 'NextMarketStartTime',
  NextMarketTimeUntil = 'NextMarketTimeUntil',
}

export const getFormattedTime = (baseDate: Dayjs, targetDate: Dayjs) => {
  return dayjs.duration(baseDate.diff(targetDate)).format('HH:mm:ss');
};

export const getNextMarketMorningInfo = (type: NextMarketInfoType, morningStartDate: Dayjs, current: Dayjs) => {
  switch (type) {
    case NextMarketInfoType.NextMarketTimeUntil:
      return getFormattedTime(morningStartDate, current); // Returns time left until next morning market
    default:
      return MARKET_MORNING_START; // Returns the start time of next morning market
  }
};

export const getNextMarketAfternoonInfo = (type: NextMarketInfoType, afternoonStartDate: Dayjs, current: Dayjs) => {
  switch (type) {
    case NextMarketInfoType.NextMarketTimeUntil:
      return getFormattedTime(afternoonStartDate, current); // Returns time left until next afternoon market
    default:
      return MARKET_AFTERNOON_START; // Returns the start time of next afternoon market
  }
};

export const getNextMarketInfo = (type: NextMarketInfoType) => {
  const current = dayjs();
  const morningStartDate = dayjs(MARKET_MORNING_START, 'HH:mm');
  const afternoonStartDate = dayjs(MARKET_AFTERNOON_START, 'HH:mm');
  if (current.isBefore(morningStartDate, 'minutes')) {
    return getNextMarketMorningInfo(type, morningStartDate, current);
  } else if (current.isBefore(afternoonStartDate, 'minutes')) {
    return getNextMarketAfternoonInfo(type, afternoonStartDate, current);
  } else {
    return DEFAULT_TIME;
  }
};

export enum CurrentMarketInfoType {
  CurrentMarketSlot = 'CurrentMarketSlot',
  CurrentMarketName = 'CurrentMarketName',
  CurrentMarketTimeLeft = 'CurrentMarketTimeLeft',
}

export const getCurrentMarketInfo = (type: CurrentMarketInfoType) => {
  const current = dayjs();
  const morningEndDate = dayjs(MARKET_MORNING_END, 'HH:mm');
  const afternoonEndDate = dayjs(MARKET_AFTERNOON_END, 'HH:mm');
  if (current.isBefore(morningEndDate, 'minutes')) {
    switch (type) {
      case CurrentMarketInfoType.CurrentMarketSlot:
        return MARKET_MORNING_START + '-' + MARKET_MORNING_END; // Returns morning market slot
      case CurrentMarketInfoType.CurrentMarketTimeLeft:
        return getFormattedTime(morningEndDate, current); // Returns the morning market closing time
      default:
        return 'morning'; // Returns the name of morning market
    }
  } else {
    switch (type) {
      case CurrentMarketInfoType.CurrentMarketSlot:
        return MARKET_AFTERNOON_START + '-' + MARKET_AFTERNOON_END; // Returns afternoon market slot
      case CurrentMarketInfoType.CurrentMarketTimeLeft:
        return getFormattedTime(afternoonEndDate, current); // Returns afternoon market closing time
      default:
        return 'afternoon'; // Returns the name of afternoon market
    }
  }
};

export const getIsWeekend = (current = dayjs()) => {
  return [SATERDAY_DAY_INDEX, SUNDAY_DAY_INDEX].includes(current.day());
};

export const getTimeLeft = () =>
  getMarketisOpen()
    ? getCurrentMarketInfo(CurrentMarketInfoType.CurrentMarketTimeLeft)
    : getNextMarketInfo(NextMarketInfoType.NextMarketTimeUntil);

export const isSelectedNextDay = (selectedDay: Dayjs) => {
  const nextDay = dayjs().add(1, 'day');
  return selectedDay.isSame(nextDay, 'day');
};

export const getDateTimeMarketIsOpenLabel = () => {
  const timeLeft = getTimeLeft();
  if (timeLeft === DEFAULT_TIME) {
    const formattedDate = dayjs()
      .add(8 - dayjs().day(), 'days')
      .format('dddd');
    return !getIsWeekend(dayjs().add(1, 'day'))
      ? `tomorrow at ${MARKET_MORNING_START}`
      : `on ${formattedDate} at ${MARKET_MORNING_START}`;
  }
  return `in ${timeLeft}`;
};
