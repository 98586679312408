import { LoadingButton } from '@mui/lab';
import { Skeleton, Button, Alert, Snackbar, Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { MdDownload } from 'react-icons/md';
import { logError } from 'services/logging';
import { BuyersMarketImage } from 'screens/BuyersMarket/BuyersMarketImage';
import { useFeatures } from 'hooks/useFeatures';
import { downloadFile } from 'utils/download-file';
import { isIOS } from 'utils/is-ios';
import { MarketImage } from 'generated/graphql';
import { QualityChipVariant } from './QualityChip';
import PDF from './pdf.svg?react';

const STORAGE_URL = 'https://firebasestorage.googleapis.com';

export enum SlideImageType {
  Image = 'image',
  MarketImage = 'marketImage',
  Pdf = 'pdf',
}

const SlideImage = ({
  imageURL,
  marketImage,
  sku = '',
  size,
  noClip = false,
  showDownloadPDF = false,
  isDownloading,
  thumbnail,
  onDownloadPdf,
  qualityChipVariant = QualityChipVariant.BadgeNoLabel,
}: {
  imageURL?: string;
  marketImage?: MarketImage;
  sku?: string;
  size?: number;
  noClip?: boolean;
  isDownloading: boolean;
  showDownloadPDF?: boolean;
  thumbnail?: string;
  onDownloadPdf: (url: string) => void;
  qualityChipVariant?: QualityChipVariant;
}) => {
  const { isEnabled } = useFeatures();
  const isQualityControlEnabled = isEnabled('QualityControl');

  const [slideType, setSlideType] = useState<SlideImageType | null>(null);
  const [image, setImage] = useState<string>();
  const isIOSDevice = isIOS();

  const url = (isQualityControlEnabled && marketImage ? marketImage.image : imageURL) ?? '';

  useEffect(() => {
    const getContentType = async () => {
      try {
        if (isQualityControlEnabled && marketImage) {
          setSlideType(SlideImageType.MarketImage);
          return;
        }
        if (!url?.includes(STORAGE_URL)) {
          setSlideType(SlideImageType.Image);
          return;
        }
        const response = await fetch(url);
        const contentType = response.headers.get('Content-Type');
        setSlideType(
          contentType?.startsWith('image/') || contentType?.startsWith('application/octet-stream')
            ? SlideImageType.Image
            : SlideImageType.Pdf,
        );
        setImage(URL.createObjectURL(await response.blob()));
      } catch (error: any) {
        logError('Could not download image on fetch', error.message);
      }
    };
    if (url) {
      setSlideType(null);
      getContentType();
    }
  }, [url, marketImage, isQualityControlEnabled]);

  if (!slideType) {
    return <Skeleton variant="rectangular" sx={{ height: size, width: size }} />;
  }

  return (
    <>
      {slideType === SlideImageType.Image && image && (
        <Box
          component="img"
          src={image}
          loading="eager"
          sx={(theme) => ({
            width: size,
            height: size,
            objectFit: noClip ? 'contain' : 'cover',
            objectPosition: 'center',
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.grey[200],
          })}
        />
      )}
      {isQualityControlEnabled && slideType === SlideImageType.MarketImage && (
        <BuyersMarketImage
          marketImage={marketImage}
          sku={sku}
          size={size}
          noClip={noClip}
          thumbnail={thumbnail}
          qualityChipVariant={qualityChipVariant}
        />
      )}
      {slideType === SlideImageType.Pdf && (
        <>
          {showDownloadPDF ? (
            <Stack alignItems="center" justifyContent="center" sx={{ height: size, width: size }}>
              {isIOSDevice ? (
                <Button href={url ?? ''} variant="contained" target="_blank" download startIcon={<MdDownload />}>
                  Download PDF
                </Button>
              ) : (
                <LoadingButton
                  variant="contained"
                  loading={isDownloading}
                  startIcon={<MdDownload />}
                  onClick={() => onDownloadPdf(url)}
                >
                  Download PDF
                </LoadingButton>
              )}
            </Stack>
          ) : (
            <PDF />
          )}
        </>
      )}
    </>
  );
};

const ImageSlider = ({
  urls,
  marketImages,
  name = '',
  sku = '',
}: {
  urls?: string[];
  marketImages?: MarketImage[];
  name?: string;
  sku?: string;
}) => {
  const { isEnabled } = useFeatures();
  const isQualityControlEnabled = isEnabled('QualityControl');

  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [showError, setShowError] = useState(false);
  const [isDownloading, setDownloading] = useState(false);

  const handleDownloadPdf = async () => {
    try {
      setDownloading(true);
      const file =
        isQualityControlEnabled && marketImages
          ? marketImages[activeImageIndex].image
          : urls
            ? urls[activeImageIndex]
            : '';
      if (file.length > 0) await downloadFile(file, `${name}.pdf`);
    } catch (error) {
      setShowError(true);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Stack direction={'column'} alignItems="center" gap={3} sx={{ width: '100%' }}>
        {isQualityControlEnabled && marketImages ? (
          <SlideImage
            marketImage={marketImages[activeImageIndex]}
            size={200}
            noClip
            isDownloading={isDownloading}
            onDownloadPdf={handleDownloadPdf}
            showDownloadPDF
            sku={sku}
            qualityChipVariant={QualityChipVariant.BadgeLabel}
          />
        ) : urls ? (
          <SlideImage
            imageURL={urls[activeImageIndex]}
            size={200}
            noClip
            isDownloading={isDownloading}
            onDownloadPdf={handleDownloadPdf}
            showDownloadPDF
          />
        ) : null}
        <Stack
          direction="row"
          alignItems="center"
          alignSelf="left"
          sx={{
            width: '100%',
            overflowX: 'auto',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '::-webkit-scrollbar': { display: 'none' },
          }}
          gap={1}
        >
          {isQualityControlEnabled && marketImages
            ? marketImages.map((marketImage, index) => {
                if (!marketImage || marketImage.image.length === 0) {
                  return null;
                }
                return (
                  <Box
                    key={index}
                    component="button"
                    sx={(theme) => ({
                      width: '68px',
                      height: '68px',
                      minWidth: '68px',
                      border: `1px solid ${index === activeImageIndex ? theme.palette.primary.main : theme.palette.grey[200]}`,
                      borderRadius: theme.spacing(1),
                      cursor: 'pointer',
                      overflow: 'hidden',
                      padding: 0,
                    })}
                    onClick={() => setActiveImageIndex(index)}
                  >
                    <SlideImage
                      marketImage={marketImage}
                      size={68}
                      isDownloading={isDownloading}
                      onDownloadPdf={handleDownloadPdf}
                      sku={sku}
                      thumbnail={'64'}
                    />
                  </Box>
                );
              })
            : urls
              ? urls.map((url, index) => {
                  if (!url || url.length === 0) {
                    return null;
                  }
                  return (
                    <Box
                      key={index}
                      component="button"
                      sx={(theme) => ({
                        width: '68px',
                        height: '68px',
                        minWidth: '68px',
                        border: `1px solid ${index === activeImageIndex ? theme.palette.primary.main : theme.palette.grey[200]}`,
                        borderRadius: theme.spacing(1),
                        cursor: 'pointer',
                        overflow: 'hidden',
                        padding: 0,
                      })}
                      onClick={() => setActiveImageIndex(index)}
                    >
                      <SlideImage
                        imageURL={url}
                        size={68}
                        isDownloading={isDownloading}
                        onDownloadPdf={handleDownloadPdf}
                        thumbnail={'64'}
                      />
                    </Box>
                  );
                })
              : null}
        </Stack>
      </Stack>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={showError}
        autoHideDuration={3000}
        onClose={() => setShowError(false)}
      >
        <Alert onClose={() => setShowError(false)} severity="error">
          Failed to download Image/PDF.
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default ImageSlider;
