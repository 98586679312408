import { Box } from '@mui/material';
import { BsArrowDownRight, BsArrowUpRight, BsHourglassSplit } from 'react-icons/bs';
import { MdAlarm, MdAssistantPhoto, MdCategory, MdOutlinePause } from 'react-icons/md';
import { BaseTheme } from 'config/base-theme';
import { useFeatures } from 'hooks/useFeatures';
import ProductIcon from './ProductIcon/ProductIcon';
import { getProductImageParts, IconStatus } from './getProductImageParts';

const checkIfPartialPallets = (cases?: string) => {
  return cases?.includes('/');
};

const INCOMPLETE_STATUSES = [
  IconStatus.casesDispatched,
  IconStatus.casesOrdered,
  IconStatus.casesReceived,
  IconStatus.casesWarnTime,
  IconStatus.casesOnHold,
];

const INCOMPLETE_STATUSES_NEW = [
  IconStatus.casesDispatched,
  IconStatus.casesOrdered,
  IconStatus.casesReceived,
  IconStatus.casesWarnTime,
  IconStatus.casesOnHold,
  IconStatus.casesDeviation,
  IconStatus.casesFlag,
];

const iconContainerStyle = (theme: BaseTheme) => ({
  display: 'inline-block',
  width: 33,
  height: 33,
  position: 'relative',
  top: theme.spacing(0.5),
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(5),
  marginBottom: theme.spacing(0.75),
  border: `1px solid ${theme.palette.divider}`,
});

const ProductIcons = ({ value, cases }: { value: string; cases?: string }) => {
  const { isEnabled } = useFeatures();
  const isArrivalsDeparturesEnabled = isEnabled('ArrivalsDepartures');

  const { products, status, flag } = getProductImageParts(value);
  const hasPartialPallets = checkIfPartialPallets(cases);
  const isIncompleteStatus = isArrivalsDeparturesEnabled
    ? INCOMPLETE_STATUSES_NEW.includes(status)
    : INCOMPLETE_STATUSES.includes(status);
  const belowMOQ = isIncompleteStatus && hasPartialPallets;
  const isFlagged = belowMOQ || flag === 'true'; // isFlagged means tag should be orange

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        minHeight: 40,
        width: 60,
        position: 'relative',
      }}
    >
      {products[2] && (
        <Box
          component="span"
          sx={(theme) => ({
            ...iconContainerStyle(theme),
            marginRight: theme.spacing(-3),
            opacity: 0.5,
            background: theme.palette.grey[600],
          })}
        >
          <ProductIcon sku={products[2]} />
        </Box>
      )}
      {products[1] && (
        <Box
          component="span"
          sx={(theme) => ({
            ...iconContainerStyle(theme),
            marginRight: theme.spacing(-2.25),
          })}
        >
          <ProductIcon sku={products[1]} />
        </Box>
      )}
      {products[0] && (
        <Box component="span" sx={iconContainerStyle}>
          <ProductIcon sku={products[0]} />
        </Box>
      )}
      {cases && products.length > 0 && (
        <Box
          sx={(theme) => ({
            display: 'flex',
            lineHeight: 1,
            alignItems: 'center',
            gap: theme.spacing(0.25),
            wordBreak: 'normal',
            fontFamily: theme.typography.fontFamily,
            fontSize: '0.7rem',
            fontWeight: 500,
            color: theme.palette.common.white,
            borderRadius: theme.spacing(2),
            padding: theme.spacing(0.5, 0.75),
            position: 'absolute',
            zIndex: 4,
            margin: theme.spacing(-3, -0.75, 0, 0),
            background: () => {
              if (isFlagged) return theme.palette.info.main;
              return theme.palette.primary.main;
            },
          })}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <span>{cases}</span>
            {status === IconStatus.casesReceived && <Box component={BsArrowDownRight} />}
            {status === IconStatus.casesDispatched && <Box component={BsArrowUpRight} />}
            {status === IconStatus.casesWarnTime && (
              <Box component={isArrivalsDeparturesEnabled ? MdAlarm : BsHourglassSplit} />
            )}
            {status === IconStatus.casesOnHold && <Box component={MdOutlinePause} />}
            {isArrivalsDeparturesEnabled && status === IconStatus.casesDeviation && <Box component={MdCategory} />}
            {isArrivalsDeparturesEnabled && status === IconStatus.casesFlag && <Box component={MdAssistantPhoto} />}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProductIcons;
