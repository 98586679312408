import { Button, CircularProgress } from '@mui/material';

const ExpandableTablePending = () => {
  return (
    <Button sx={(theme) => ({ margin: theme.spacing(-1.5, 2, 0) })}>
      Updates pending
      <CircularProgress size={20} color="secondary" sx={{ marginLeft: 1.5, minWidth: '20px', minHeight: '20px' }} />
    </Button>
  );
};

export default ExpandableTablePending;
