import { BaseTheme } from 'config/base-theme';
import { FontStyle, FontType, TableCell as TableCellData, TableType } from 'generated/graphql';

export interface CellStyle {
  theme: BaseTheme;
  cellData: TableCellData;
  type: TableType;
  hideLastBorder?: boolean;
  isNested?: boolean;
  shiftRight?: boolean;
  hideLeftBorder?: boolean;
  hideRightBorder?: boolean;
  isInline?: boolean;
  index: number;
}

const ExpandableCellStyle = (style: CellStyle) => {
  const { theme, cellData, type, hideLastBorder, isNested, shiftRight, hideLeftBorder, hideRightBorder, isInline } =
    style;

  return {
    ...(type === TableType.Statistics && {
      '&.MuiTableCell-root': {
        borderRight: hideRightBorder ? 'hidden' : '',
        borderLeft: hideLeftBorder ? 'hidden' : '',
        background: !isNested && theme.palette.tertiary.light,
        paddingLeft: shiftRight ? theme.spacing(2) : '',
        verticalAlign: 'bottom',
        paddingY: theme.spacing(1),
        textAlign: cellData.align,
        ...(style.index === 0 && {
          position: 'sticky',
          left: 0,
          zIndex: 1,
          boxShadow: 'inset -10px 0 1px -10px rgba(0,0,0,0.4)',
          borderBottom: 'none',
          borderRight: 'none',
        }),
        display: isInline ? 'flex' : '',
        gap: 1,
      },
    }),
    ...(cellData.fontStyle === FontStyle.Bold && { fontWeight: theme.typography.fontWeightMedium }),
    ...(cellData.fontStyle === FontStyle.Badge && {
      padding: theme.spacing(0.4, 1),
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.light,
      borderRadius: theme.spacing(2),
    }),
    ...(cellData.fontStyle === FontStyle.Highlighted && {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.light,
    }),
    ...((cellData.fontType === FontType.Normal || cellData.fontStyle === FontStyle.Badge) && {
      fontFamily: theme.typography.fontFamily,
    }),
    ...(cellData.fontType === FontType.Mono && { fontFamily: 'Roboto Mono' }),
    ...(hideLastBorder && { border: 0 }),
    ...(type === TableType.Reporting && { borderBottom: `2px solid ${theme.palette.background.default}` }),
    ...(type !== TableType.Detail &&
      type !== TableType.Reporting && { borderBottom: `1px solid ${theme.palette.common.white}` }),
  };
};

export default ExpandableCellStyle;
