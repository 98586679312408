import { CUSTOM_SCHEME_PATH } from 'services/api-constants';
import { useSwitchProfile } from './useSwitchProfile';
import useUser from './useUser';

/** Use handle method hook */
const useHandleMethod = () => {
  const onSwitchProfile = useSwitchProfile();
  const { onLogout, user } = useUser();

  const handleMethod = async (
    urlEntity: URL,
    setIsLoading: (value: boolean) => void,
    setErrorMessage: (value: string) => void,
  ) => {
    if (urlEntity.pathname.startsWith(CUSTOM_SCHEME_PATH.Logout)) {
      await onLogout();
    } else if (urlEntity.pathname.startsWith(CUSTOM_SCHEME_PATH.SwitchProfile)) {
      const selectedId = urlEntity.searchParams.get('id') || '';
      if (user?.profileId?.toString() === selectedId.toString()) {
        return;
      }
      setIsLoading(true);
      const error = await onSwitchProfile(selectedId);
      if (error) {
        setErrorMessage(error.message);
        setIsLoading(false);
        return;
      }
      window.location.reload();
    }
  };

  return handleMethod;
};

export default useHandleMethod;
