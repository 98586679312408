import { IconButtonProps, Typography, TypographyProps } from '@mui/material';
import { ReactNode } from 'react';
import ShareLink from './ShareLink';

const HTTP_PREFIX = 'http';

export const NILE_HUB =
  'https://www.google.com/maps/place/Nile.ag/@-25.9283328,28.1377906,17z/data=!3m1!4b1!4m6!3m5!1s0x1e951577f4b1445b:0x844764f53f1ec40f!8m2!3d-25.9283328!4d28.1377906!16s%2Fg%2F11rxpmkd51?entry=tts&shorturl=1';

const getAddressLink = (address: string) => `https://maps.google.com/?q=${encodeURIComponent(address)}`;

interface AddressProps extends Omit<TypographyProps, 'color'> {
  value: string;
  children: ReactNode;
  iconClassName?: string;
  color?: IconButtonProps['color'];
}

const Address = ({ value, children, sx, color, iconClassName, ...rest }: AddressProps) => {
  const addressLink = value.startsWith(HTTP_PREFIX) ? value : getAddressLink(value);

  return (
    <>
      <Typography
        variant="inherit"
        component="span"
        sx={[
          {
            display: 'inline-flex',
            alignItems: 'center',
            gap: 1,
            ...sx,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...rest}
      >
        {children}
        <ShareLink title={value} url={addressLink} color={color} className={iconClassName} />
      </Typography>
    </>
  );
};

export default Address;
