import { useEffect, useState } from 'react';

const useDetectKeyboardOpen = (): boolean => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  useEffect(() => {
    const handleFocus = () => {
      if (document.activeElement?.tagName.toLowerCase() === 'input') {
        setIsFocused(true);
      }
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    document.addEventListener('focus', handleFocus, true);
    document.addEventListener('blur', handleBlur, true);

    return () => {
      document.removeEventListener('focus', handleFocus, true);
      document.removeEventListener('blur', handleBlur, true);
    };
  }, []);

  return isFocused;
};

export default useDetectKeyboardOpen;
