import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { BaseTheme } from 'config/base-theme';
import { SubTable as SubTableType } from 'generated/graphql';
import RowDetailItem from './RowDetailItem';

const boxStyle = (theme: BaseTheme) => ({
  borderCollapse: 'separate',
  padding: theme.spacing(1.2, 1.5, 0),
  background: theme.palette.grey[100],
  borderRadius: theme.spacing(1),
});

const SubTable = ({ data }: { data: SubTableType }) => {
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={7}>
        <Table sx={(theme) => ({ backgroundColor: theme.palette.grey[100], borderRadius: theme.spacing(1) })}>
          <TableHead>
            <TableRow>
              {data?.columns?.map((col) => (
                <TableCell
                  key={col.value}
                  align={col?.align.toLowerCase() as 'left' | 'right'}
                  sx={(theme) => ({ paddingY: theme.spacing(1) })}
                >
                  {col.value}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.rows?.map((row, index) => (
              <TableRow key={index}>
                {row?.map((cell) => (
                  <TableCell
                    sx={{ border: index === data.rows.length - 1 ? 'none' : 'auto' }}
                    key={cell.value}
                    align={cell?.align.toLowerCase() as 'left' | 'right'}
                  >
                    {cell.value}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12} md={5}>
        <Box
          sx={(theme) => ({
            ...boxStyle(theme),
            [theme.breakpoints.down('md')]: {
              margin: theme.spacing(-6, 0, 0),
            },
          })}
        >
          {data.items.map((item, i) => (
            <RowDetailItem data={item} key={`${item.info.value + i}`} />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SubTable;
