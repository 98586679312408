import { createContext, useContext, useMemo, useState, ReactNode } from 'react';
import { makeClient } from 'services/urql';
import { Client, Provider as GraphQLProvider } from 'urql';
import useUser from 'hooks/useUser';

interface GraphState {
  client: Client | null;
  resetClient: () => void;
}

const GraphContext = createContext<GraphState>({
  client: null,
  resetClient: () => undefined,
});

interface GraphProviderProps {
  children: ReactNode;
}

const GraphProvider = ({ children }: GraphProviderProps) => {
  const [client, setClient] = useState(makeClient());
  const { isInitialised } = useUser();

  const value = useMemo(
    () => ({
      client,
      resetClient: () => {
        setClient(makeClient());
      },
    }),
    [client],
  );

  if (!isInitialised) return null; // Wait for initial user state to be set

  return (
    <GraphContext.Provider value={value}>
      <GraphQLProvider value={client}>{children}</GraphQLProvider>
    </GraphContext.Provider>
  );
};

export const useGraphState = () => useContext(GraphContext);

export default GraphProvider;
