import { Dayjs } from 'dayjs';
import dayjs from 'services/dayjs';

const isBetweenHours = (current: Dayjs, start: string, end: string) => {
  const startDate = dayjs(start, 'HH:mm');
  const endDate = dayjs(end, 'HH:mm');
  const isSame = current.isSame(startDate, 'minutes') || current.isSame(endDate, 'minutes');
  const isBetween = current.isAfter(startDate, 'minutes') && current.isBefore(endDate, 'minutes');
  return isSame || isBetween;
};

export default isBetweenHours;
