import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MdAddCircle } from 'react-icons/md';
import { getPlatformTitle } from 'services/theming';
import { useClient } from 'urql';
import DetailedConfirmDialog from 'components/DetailedConfirmDialog';
import { BaseTheme } from 'config/base-theme';
import { useFeatures } from 'hooks/useFeatures';
import useSearch from 'hooks/useSearch';
import useUser from 'hooks/useUser';
import { createButtonType } from 'utils/createButtonType';
import { buildUpdatedForm, getFormValues, parseForm, replaceFormFields } from 'utils/form';
import {
  Field,
  FormType,
  InputType,
  Form as FormData,
  TableDialog as TableDialogType,
  useFormQuery,
  CascadingSelectOptionsDocument,
  CascadingSelectOptionsQuery,
  QueryCascadingSelectOptionsArgs,
  useSubmitFormMutation,
  FormInputNew,
  ButtonVariant,
  ButtonIcon,
  useSubmitFormNewMutation,
  useFormNewQuery,
  RefetchType,
  FormInput,
} from 'generated/graphql';
import ActionSnackbar from './ActionSnackbar';
import BorderLinearProgress from './BorderLinearProgress';
import CreateAlertGraphic from './CreateAlertConfirmGraphic.svg';
import ErrorSnackbar from './ErrorSnackbar';
import Form from './Form';
import NetworkError from './NetworkError';
import NetworkLoading from './NetworkLoading';
import TableDialog from './TableDialog';
import SuccessGraphic from './success.svg';

const PLATFORM_TITLE = getPlatformTitle();

const submitContainerStyle = (theme: BaseTheme) => ({
  marginTop: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
});

const getFormState = (data: FormData) => {
  return {
    ...data,
    fields: data.fields.map((f) =>
      f.type === InputType.Images
        ? {
            ...f,
            values: f.value.length > 0 ? [...f.values, f.value] : f.values,
          }
        : f,
    ),
  };
};

const getConfirmDialogGraphic = (type: FormType) => {
  switch (type) {
    case FormType.CreateAlertBuyersMarket:
      return CreateAlertGraphic;
    default:
      return SuccessGraphic;
  }
};

export enum ConfirmDialogVariant {
  Default = 'Default',
  Detailed = 'Detailed', // Detailed means dialog contains graphic, title, subtitle and action buttons
}
export interface FormDialogState {
  open: boolean;
  type: FormType;
  editIDs?: string[];
  eTags?: string[];
  addButton?: boolean;
  undoTitle?: string;
  confirmDialogVariant?: ConfirmDialogVariant;
  hideDialogActions?: boolean;
}

export interface FormDialogProps extends FormDialogState {
  onClose: (success?: boolean, reason?: string) => void;
  onSubmit?: (success?: boolean) => void;
  handleAddCases?: () => void;
}

const FormDialog = ({
  open,
  type,
  editIDs = [],
  eTags = [],
  addButton,
  confirmDialogVariant = ConfirmDialogVariant.Default,
  undoTitle = 'Click to undo changes',
  hideDialogActions = false,
  onClose,
  onSubmit = () => {},
  handleAddCases,
}: FormDialogProps) => {
  const { isEnabled } = useFeatures();
  const isFormSearchPaginationEnabled = isEnabled('FormSearchPagination');

  const [getParam] = useSearch();
  const { user } = useUser();
  const profileID = getParam('profileID') ?? getParam('profile') ?? user?.profileId.toString() ?? '0';
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const [showUndo, setShowUndo] = useState(false);

  const [formState, setFormState] = useState<FormData | undefined>();
  const prevFormState = useRef<FormData | undefined>();
  const [, submitForm] = useSubmitFormMutation();
  const [, submitFormNew] = useSubmitFormNewMutation();

  const [errorBar, setErrorBar] = useState<string | undefined>(undefined);
  const [confirmDialog, setConfirmDialog] = useState<TableDialogType>();

  const [formNewResult] = useFormNewQuery({
    variables: {
      input: {
        type,
        profileID,
        ids: editIDs,
        eTag: eTags,
        paginationOption: {
          ids: [],
          pageNumber: 0,
          pageSize: 0,
          type: RefetchType.Unknown,
        },
        search: '',
      },
    },
    requestPolicy: 'network-only',
    pause: !isFormSearchPaginationEnabled || type === FormType.CreateAlertBuyersMarket || !open,
  });

  const [formResult] = useFormQuery({
    variables: {
      input: {
        type,
        profileID,
        ids: editIDs,
        eTag: eTags,
      },
    },
    pause: isFormSearchPaginationEnabled || type === FormType.CreateAlertBuyersMarket || !open,
    requestPolicy: 'network-only',
  });

  const { data, fetching, error } = formResult;
  const { data: dataNew, fetching: fetchingNew, error: errorNew } = formNewResult;

  let apiMessage;
  if (isFormSearchPaginationEnabled) {
    if (fetchingNew) {
      apiMessage = (
        <Box
          sx={(theme) => ({
            width: '200px',
            textAlign: 'center',
            margin: theme.spacing(4, 'auto'),
          })}
        >
          <BorderLinearProgress variant="indeterminate" />
        </Box>
      );
    } else if (errorNew) {
      apiMessage = <Typography variant="h5">{errorNew.message}</Typography>;
    } else if (!dataNew) {
      apiMessage = <Typography variant="h5">Could not load dialog</Typography>;
    }
  } else {
    if (fetching) {
      apiMessage = (
        <Box
          sx={(theme) => ({
            width: '200px',
            textAlign: 'center',
            margin: theme.spacing(4, 'auto'),
          })}
        >
          <BorderLinearProgress variant="indeterminate" />
        </Box>
      );
    } else if (error) {
      apiMessage = <Typography variant="h5">{error.message}</Typography>;
    } else if (!data) {
      apiMessage = <Typography variant="h5">Could not load dialog</Typography>;
    }
  }

  const clearError = () => setErrorBar(undefined);

  useEffect(() => {
    if (isFormSearchPaginationEnabled && dataNew?.formNew) {
      const form = getFormState(dataNew.formNew as FormData);
      prevFormState.current = form;
      setFormState(parseForm(form));
    }
    if (!isFormSearchPaginationEnabled && data?.form) {
      const form = getFormState(data.form as FormData);
      prevFormState.current = form;
      setFormState(parseForm(form));
    }
  }, [data, dataNew, isFormSearchPaginationEnabled]);

  const handleSubmit = useCallback(
    async (state: FormData, canShowUndo = true) => {
      try {
        if (!data?.form) return;
        setLoading(true);
        setErrorBar('');

        if (type === FormType.CreateAlertBuyersMarket) {
          setConfirmDialog({
            title: 'Alerts created successfully',
            info: '',
            sections: [],
            buttons: [
              createButtonType({
                variant: ButtonVariant.Text,
                icon: ButtonIcon.Card,
                value: 'Manage alerts',
              }),
              createButtonType({
                variant: ButtonVariant.Contained,
                icon: ButtonIcon.Card,
                value: 'Ok',
              }),
            ],
          });
          return;
        } else {
          const input: FormInput = {
            type,
            fields: await getFormValues(state),
            confirmDialog: false,
            eTag: data.form.eTag,
          };
          const result = await submitForm({ input: input });

          if (result.error) {
            setErrorBar(result.error.message);
            return;
          }

          if (result.data?.form.hasDialog) {
            setConfirmDialog(result.data?.form.dialog);
            return;
          }
        }

        if (canShowUndo && state.allowUndo) {
          setShowUndo(true);
        }

        if (onSubmit) {
          onSubmit(true);
        }
        onClose(true);
      } catch (error) {
        setErrorBar('An error occurred while saving the form.');
      } finally {
        setLoading(false);
      }
    },
    [onClose, submitForm, type, onSubmit, data?.form],
  );

  const handleSubmitNew = useCallback(
    async (state: FormData, canShowUndo = true) => {
      try {
        if (!dataNew?.formNew) return;
        setLoading(true);
        setErrorBar('');

        if (type === FormType.CreateAlertBuyersMarket) {
          setConfirmDialog({
            title: 'Alerts created successfully',
            info: '',
            sections: [],
            buttons: [
              createButtonType({
                variant: ButtonVariant.Text,
                icon: ButtonIcon.Card,
                value: 'Manage alerts',
              }),
              createButtonType({
                variant: ButtonVariant.Contained,
                icon: ButtonIcon.Card,
                value: 'Ok',
              }),
            ],
          });
          return;
        } else {
          const input: FormInputNew = {
            type,
            fields: await getFormValues(state),
            confirmDialog: false,
            eTag: dataNew.formNew.eTag,
          };
          const result = await submitFormNew({ input: input });

          if (result.error) {
            setErrorBar(result.error.message);
            return;
          }

          if (result.data?.formNew.hasDialog) {
            setConfirmDialog(result.data?.formNew.dialog);
            return;
          }
        }

        if (canShowUndo && state.allowUndo) {
          setShowUndo(true);
        }

        if (onSubmit) {
          onSubmit(true);
        }
        onClose(true);
      } catch (error) {
        setErrorBar('An error occurred while saving the form.');
      } finally {
        setLoading(false);
      }
    },
    [onClose, submitFormNew, type, onSubmit, dataNew?.formNew],
  );

  const handleUndo = () => {
    setShowUndo(false);
    if (!isFormSearchPaginationEnabled && prevFormState.current) {
      handleSubmit(prevFormState.current, false);
    } else if (isFormSearchPaginationEnabled && prevFormState.current) {
      handleSubmitNew(prevFormState.current, false);
    }
  };

  const handleFieldUpdate = async (field: Field, value: string, values?: string[]) => {
    if (field.hasCascade) {
      setLoading(true);
      const result = await client
        .query<CascadingSelectOptionsQuery, QueryCascadingSelectOptionsArgs>(CascadingSelectOptionsDocument, {
          input: {
            type,
            field: {
              id: field.id,
              value,
              values: values || [],
              entityID: field.entityID,
            },
            ids: editIDs,
          },
        })
        .toPromise();

      if (result.error) {
        setErrorBar(result.error.message);
        return;
      }

      const fieldsToReplace = result.data?.cascadingSelectOptions;
      if (fieldsToReplace) {
        setFormState((oldForm) => oldForm && replaceFormFields(oldForm, fieldsToReplace));
      }
      setLoading(false);
    }

    setFormState((oldForm) => oldForm && buildUpdatedForm(oldForm, field, value, values));
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialog(undefined);
    onSubmit(true);
  };

  if (confirmDialog && confirmDialogVariant === ConfirmDialogVariant.Detailed) {
    return (
      <DetailedConfirmDialog
        data={confirmDialog}
        graphic={getConfirmDialogGraphic(type)}
        title={confirmDialog.title}
        subtitle={
          type === FormType.CreateAlertBuyersMarket
            ? 'Alerts successfully created, you will now receive notifications when new produce gets added.'
            : `You have linked your Market account to ${PLATFORM_TITLE}`
        }
        actionButtons={confirmDialog.buttons} // TODO: Confirm this works for technofresh
        onDialogClose={() => {
          onClose();
          setConfirmDialog(undefined);
        }}
      />
    );
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={(_, reason) => onClose(false, reason)}
        sx={(theme) => ({ '& .MuiDialog-paper': { p: 1, [theme.breakpoints.down('sm')]: { p: 0 } } })}
      >
        {apiMessage ||
          (((!isFormSearchPaginationEnabled && data) || (isFormSearchPaginationEnabled && dataNew)) && (
            <>
              <DialogContent
                sx={(theme) => ({ minWidth: '420px', [theme.breakpoints.down('sm')]: { minWidth: '100vw' } })}
              >
                {formResult.fetching || formResult.stale || formNewResult.fetching || formNewResult.stale ? (
                  <NetworkLoading hideHero />
                ) : !isFormSearchPaginationEnabled && formResult.error ? (
                  <NetworkError message={formResult.error.message} />
                ) : isFormSearchPaginationEnabled && formNewResult.error ? (
                  <NetworkError message={formNewResult.error.message} />
                ) : (
                  <>
                    <Form
                      form={formState}
                      onFieldUpdate={handleFieldUpdate}
                      onClose={onClose}
                      isLoading={loading || fetching || fetchingNew}
                      isFormDialog
                    />
                    {addButton && handleAddCases && (
                      <Box sx={submitContainerStyle}>
                        <Button variant="outlined" startIcon={<MdAddCircle />} onClick={() => handleAddCases()}>
                          Add case
                        </Button>
                      </Box>
                    )}
                    <TableDialog data={confirmDialog} onClose={handleConfirmDialogClose} />
                  </>
                )}
              </DialogContent>
              {!hideDialogActions && (
                <DialogActions sx={{ px: 3, pb: 2 }}>
                  <Stack direction="row" justifyContent="space-between" gap={1} sx={{ width: '100%' }}>
                    <Button variant="text" color="primary" onClick={() => onClose()} sx={{ width: '100%' }}>
                      Cancel
                    </Button>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      disabled={!formState}
                      loading={loading}
                      onClick={() =>
                        isFormSearchPaginationEnabled ? handleSubmitNew(formState!) : handleSubmit(formState!)
                      }
                      sx={{ width: '100%' }}
                    >
                      {(isFormSearchPaginationEnabled
                        ? formNewResult.data?.formNew.buttonText
                        : formResult.data?.form.buttonText) || 'Save'}
                    </LoadingButton>
                  </Stack>
                </DialogActions>
              )}
              {confirmDialogVariant === ConfirmDialogVariant.Default && (
                <TableDialog data={confirmDialog} onClose={() => setConfirmDialog(undefined)} />
              )}
              <ErrorSnackbar isOpen={!!errorBar} onClose={clearError}>
                {errorBar!}
              </ErrorSnackbar>
            </>
          ))}
      </Dialog>
      <ActionSnackbar
        isOpen={showUndo}
        title={undoTitle}
        actionTitle="UNDO"
        onAction={handleUndo}
        onClose={() => setShowUndo(false)}
      />
    </>
  );
};

export default FormDialog;
