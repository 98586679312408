import { Box, Typography } from '@mui/material';
import { MdRefresh } from 'react-icons/md';
import { getThemeVariant, ThemeVariant } from 'services/theming';
import { baseTheme } from 'config/base-theme';
import Button from './Button';
import Logo from './logo-color.svg?react';
import LogoFreshmark from './logo-freshmark.svg?react';
import LogoVilla from './logo-villa.svg?react';

const logoStyle = {
  maxHeight: 100,
  maxWidth: 130,
};

const NetworkError = ({ message }: { message: string }) => {
  const themeVariant = getThemeVariant();

  const handleRefresh = () => window.location.reload();

  return (
    <Box
      style={{
        width: '100%',
        textAlign: 'center',
        marginTop: baseTheme.spacing(8),
      }}
    >
      {[ThemeVariant.Nile, ThemeVariant.Inputs].includes(themeVariant) && <Logo style={logoStyle} />}
      {themeVariant === ThemeVariant.FreshMark && <LogoFreshmark style={logoStyle} />}
      {themeVariant === ThemeVariant.Villa && <LogoVilla style={logoStyle} />}
      <Typography sx={{ my: 2 }} variant="h6" color="error">
        {message}
      </Typography>
      <Button onClick={handleRefresh} startIcon={<MdRefresh size={20} />} variant="contained">
        Refresh
      </Button>
    </Box>
  );
};

export default NetworkError;
