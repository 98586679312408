/**
 * Constant values used by the API that is not directly accssible via the schema.
 */

export const DEFAULT_STRING_DELIMITER = ';';
export const DEFAULT_ID_DELIMITER = '$';
export const ROWS_PER_PAGE = 25;

export enum EDIT_SHIPMENT_CODE {
  Received = 'RECEIVED',
  Loaded = 'LOADED',
  Quantity = 'QUANTITY',
  Price = 'PRICE',
  Adjustment = 'ADJUSTMENT',
  Percentage = 'PERCENTAGE',
  InnerCasepack = 'INNER_CASE_PACK',
  CasePack = 'CASE_PACK',
  CaseType = 'CASE_TYPE',
  VolumeAdjustment = 'VOL_ADJUSTMENT',
  Block = 'BLOCK',
  RequestStatuses = 'REQUEST_STATUSES',
  HandlingFee = 'HANDLING_FEE',
  VAT = 'VAT',
}

export enum CUSTOM_SCHEME_PATH {
  Dispatch = 'dispatch',
  ShipmentTable = 'shipmentTable',
  TableDialog = 'tableDialog',
  PayNow = 'payNow',
  ExpandRow = 'expandRow',
  Form = 'form',
  SwitchProfile = 'switchProfile',
  EditHistory = 'editHistory',
  Logout = 'logout',
  Set = 'set',
  Remove = 'remove',
  Notify = 'notify',
}

export enum CUSTOM_SCHEME_PREFIX {
  Dialog = 'dialog:',
  Method = 'method:',
}
