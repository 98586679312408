import { ButtonProps } from '@mui/material';
import { useState } from 'react';
import { FaUser } from 'react-icons/fa';
import ActionList from 'components/ActionList';
import IconButton from 'components/IconButton';
import { useFeatures } from 'hooks/useFeatures';
import useProfileConfig from 'hooks/useProfileConfig';
import Button from '../Button';

const ProfileConfigButton = ({ icon = false }: { icon?: boolean }) => {
  const { data } = useProfileConfig();
  const { isEnabled } = useFeatures();
  const isProfileConfigEnabled = isEnabled('ProfileConfig');
  const [isOpen, setIsOpen] = useState(false);

  if (!isProfileConfigEnabled || !data) {
    return null;
  }

  return (
    <>
      {icon ? (
        <IconButton onClick={() => setIsOpen(true)} color={data?.color as ButtonProps['color']}>
          <FaUser />
        </IconButton>
      ) : (
        <Button
          variant={data?.variant as ButtonProps['variant']}
          color={data?.color as ButtonProps['color']}
          onClick={() => setIsOpen(true)}
        >
          {data?.value}
        </Button>
      )}
      <ActionList open={isOpen} button={data} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default ProfileConfigButton;
