import { Box, Typography, Stack, List, Tab as MuiTab, Tabs as MuiTabs, alpha } from '@mui/material';
import { ChangeEvent, ReactNode, useRef } from 'react';
import { getThemeVariant, ThemeVariant } from 'services/theming';
import useIsDarkMode from 'hooks/useIsDarkMode';
import useIsViewport from 'hooks/useIsViewport';
import useScroll from 'hooks/useScroll';
import getImagesByTheme from 'utils/imageThemeManager';
import sun from 'images/sun.svg';
import MobileHeader from './MobileHeader';
import SearchBar, { SearchbarProps } from './SearchBar';

export enum HeroCover {
  Mountain = 'mountain',
  Map = 'map',
  Swish = 'swish',
}

const Tabs = (props: {
  value: number;
  children: ReactNode;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  scrollable?: boolean;
}) => {
  const isDarkMode = useIsDarkMode();
  const isMobile = useIsViewport();
  return (
    <MuiTabs
      sx={(theme) => ({
        minHeight: 36,
        '& .MuiTabs-indicator': {
          display: 'none',
        },
        '& .MuiTabs-scrollButtons': {
          color: isDarkMode ? theme.palette.common.white : theme.palette.primary.main,
          '& svg': {
            width: '1.5rem',
            height: '1.5rem',
          },
        },
      })}
      {...props}
      variant="scrollable"
      allowScrollButtonsMobile
      scrollButtons={isMobile ? 'auto' : false}
      TabIndicatorProps={{ children: <span /> }}
    />
  );
};

const Tab = (props: { label: string; selected?: boolean }) => {
  const isDarkMode = useIsDarkMode();
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flex: 1,
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.spacing(2.5),
        [theme.breakpoints.down('md')]: {
          backgroundColor: isDarkMode ? theme.palette.primary.main : theme.palette.common.white,
          borderBottom: 'none',
          paddingBottom: 0,
        },
      })}
    >
      <MuiTab
        sx={(theme) => ({
          flex: 1,
          minWidth: 72,
          minHeight: 36,
          padding: theme.spacing(0, 2),
          textTransform: 'none',
          backgroundColor: 'inherit',
          color: theme.palette.getContrastText(theme.palette.primary.main),
          fontWeight: 400,
          fontSize: theme.typography.pxToRem(15),
          margin: theme.spacing(0, 0.25),
          whiteSpace: 'nowrap',
          borderRadius: theme.spacing(2.5),
          transition: 'all 100ms ease-in-out',
          '&:hover, &:focus': {
            backgroundColor: alpha(theme.palette.common.white, 0.1),
          },
          [theme.breakpoints.down('md')]: {
            color: theme.palette.getContrastText(isDarkMode ? theme.palette.primary.main : theme.palette.common.white),
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.background.default,
            color: `${theme.palette.text.primary} !important`,
          },
        })}
        {...props}
      />
    </Box>
  );
};

const getCoverImage = (themeVariant: ThemeVariant, cover?: HeroCover | string) => {
  const themeImages = getImagesByTheme(themeVariant);

  switch (cover) {
    case HeroCover.Mountain:
      return themeImages.mountainHero;
    case HeroCover.Map:
      return themeImages.mapHero;
    case HeroCover.Swish:
      return themeImages.swish;
    case undefined:
      return '';
    default:
      return cover;
  }
};

const HeroBottomRow = ({
  rightSection,
  onTabChange,
  tabs,
  tab,
  actions,
}: {
  rightSection: ReactNode;
  onTabChange?: (event: ChangeEvent<{}>, newValue: number) => void;
  tabs?: string[];
  tab?: number;
  actions?: ReactNode;
}) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="end" sx={{ flex: 1 }}>
      {actions}
      {/* Bottom Left */}
      <Stack direction="row">
        {onTabChange && tabs && (
          <Tabs value={tab ?? 0} onChange={(event, val) => onTabChange(event, val ?? 0)}>
            {tabs?.map((label: string) => <Tab key={label} label={label} />)}
          </Tabs>
        )}
      </Stack>
      {/* Bottom Right */}
      <Stack direction="row" alignItems="center" gap={1}>
        {rightSection}
      </Stack>
    </Stack>
  );
};

interface HeroProps {
  cover?: HeroCover | string;
  afterSearch?: ReactNode;
  actions?: ReactNode;
  beforeSearch?: ReactNode;
  afterActions?: ReactNode;
  searchProps?: SearchbarProps;
  topRight?: ReactNode; // default top right section, if topSearch true then shows in bottom right section
  isReturn?: boolean;
  mobileActions?: ReactNode; //filter / help etc
  isSticky?: boolean;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  childrenMobile?: ReactNode;
  styleVariant?: 'primary' | 'plain';
  list?: typeof List;
  tab?: number;
  isLoading?: boolean;
  tabs?: string[];
  searchbarPlacement?: SearchBarPlacement;
  grid?: ReactNode;
  fixSearch?: boolean;
  onTabChange?: (event: ChangeEvent<{}>, newValue: number) => void;
}

const DEFAULT_SCROLL_OFFSET = 140;
type SearchBarPlacement = 'topRight' | 'bottomLeft' | 'bottomRight';

const Hero = ({
  cover,
  actions,
  afterSearch,
  beforeSearch,
  afterActions,
  topRight,
  searchProps,
  isReturn = false,
  mobileActions,
  isSticky,
  title,
  subtitle,
  childrenMobile,
  styleVariant = 'primary',
  isLoading,
  list: HeroList,
  tab,
  tabs,
  grid,
  searchbarPlacement = 'bottomRight',
  fixSearch = false,
  onTabChange,
}: HeroProps) => {
  const isMobile = useIsViewport();
  const heroRef = useRef<HTMLDivElement>(null);
  const isScrolled = useScroll(heroRef.current?.offsetHeight ?? DEFAULT_SCROLL_OFFSET);
  const showFixedHeader = isScrolled && isSticky;
  const themeVariant = getThemeVariant();
  return (
    <>
      {isMobile ? (
        <MobileHeader
          title={
            typeof title === 'string' ? (
              <Typography variant="h6" component="h1">
                {title}
              </Typography>
            ) : (
              title
            )
          }
          searchProps={{
            ...searchProps,
            loading: isLoading,
          }}
          hasBackButton={isReturn}
          actions={mobileActions}
          showSearch={!!searchProps}
          childrenPadding={false}
          alwaysShowSearch={fixSearch}
          // handle search alerts
        >
          {childrenMobile}
          {tabs && onTabChange && tabs.length > 0 && (
            <Box sx={{ marginBottom: 0.5, marginX: 0.5 }}>
              <Tabs value={tab ?? 0} onChange={onTabChange}>
                {tabs.map((label) => (
                  <Tab key={label} label={label} />
                ))}
              </Tabs>
            </Box>
          )}
        </MobileHeader>
      ) : (
        <>
          <Box
            sx={(theme) => ({
              marginBottom: theme.spacing(1),
              position: showFixedHeader ? 'sticky' : 'relative',
              top: showFixedHeader ? '48px' : 'auto',
              zIndex: showFixedHeader ? 52 : 'auto',
              background: theme.palette.background.default,
            })}
          >
            {![ThemeVariant.Inputs, ThemeVariant.Villa].includes(themeVariant) &&
              cover === HeroCover.Mountain &&
              !showFixedHeader && ( // If hero is NOT sticky and page is NOT scrolled, then show hero image.
                <Stack
                  direction="row"
                  justifyContent="end"
                  sx={(theme) => ({
                    zIndex: 52,
                    marginTop: theme.spacing(-6),
                    position: 'relative',
                    top: `calc(${theme.spacing(8)} - 10px)`,
                    right: theme.spacing(10),
                    width: '100%',
                    pointerEvents: 'none',
                  })}
                >
                  <Box
                    component="img"
                    src={sun}
                    sx={{
                      width: 60,
                      height: 60,
                    }}
                  />
                </Stack>
              )}
            <Stack
              ref={heroRef}
              sx={(theme) => ({
                position: showFixedHeader ? 'sticky' : 'relative',
                top: showFixedHeader ? '56px' : 'auto',
                zIndex: showFixedHeader ? 52 : 'auto',
                backgroundColor: styleVariant === 'plain' ? 'initial' : theme.palette.primary.main,
                borderRadius: afterActions ? theme.spacing(1, 1, 0, 0) : theme.spacing(1),
              })}
            >
              <Stack
                gap={1}
                justifyContent="space-between"
                sx={(theme) => ({
                  padding: styleVariant === 'plain' ? 0 : theme.spacing(1, 2, 0.5, 3),
                  borderRadius: afterActions ? theme.spacing(1, 1, 0, 0) : theme.spacing(1),
                  minHeight: !showFixedHeader && styleVariant !== 'plain' ? 140 : 'auto',
                })}
              >
                {!showFixedHeader && cover && (
                  <Stack
                    sx={(theme) => ({
                      position: 'absolute',
                      width: '65%',
                      height: '100%',
                      top: 0,
                      right: 0,
                      display: 'flex',
                      backgroundImage: showFixedHeader ? 'none' : `url(${getCoverImage(themeVariant, cover)})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'top right',
                      backgroundSize: 'auto 100%',
                      borderRadius: afterActions ? theme.spacing(1, 1, 0, 0) : theme.spacing(1),
                    })}
                  >
                    <Stack
                      sx={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        backgroundImage: showFixedHeader ? 'none' : `url(${getCoverImage(themeVariant, 'swish')})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'left',
                        backgroundSize: 'auto 100%',
                      }}
                    />
                  </Stack>
                )}

                {!showFixedHeader && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="start"
                    sx={{ width: '100%', flex: 1, display: showFixedHeader ? 'none' : 'flex' }}
                  >
                    {/* Top Left */}
                    <Stack sx={(theme) => ({ marginTop: theme.spacing(1), zIndex: 53 })}>
                      {typeof title === 'string' ? (
                        <Typography
                          color={styleVariant === 'plain' ? 'primary.text' : 'white'}
                          variant={styleVariant === 'plain' ? 'h5' : 'h4'}
                          sx={(theme) => ({ marginBottom: theme.spacing(0.5) })}
                        >
                          {title}
                        </Typography>
                      ) : (
                        title
                      )}
                      {typeof subtitle === 'string' ? (
                        <Typography color={styleVariant === 'plain' ? 'primary.text' : 'white'} variant={'body1'}>
                          {subtitle}
                        </Typography>
                      ) : (
                        subtitle
                      )}
                    </Stack>

                    {/* Top Right */}
                    <Stack direction="row" alignItems="center" justifyContent="end" sx={{ flex: 1 }}>
                      {searchbarPlacement === 'topRight' ? (
                        <>
                          {beforeSearch}
                          {/* Search bar */}
                          {!!searchProps && (
                            <Box sx={(theme) => ({ marginLeft: theme.spacing(1) })}>
                              <SearchBar {...searchProps} />
                            </Box>
                          )}
                          {afterSearch}
                        </>
                      ) : (
                        topRight
                      )}
                    </Stack>
                  </Stack>
                )}

                {/* Bottom */}
                <HeroBottomRow
                  tab={tab}
                  tabs={tabs}
                  onTabChange={onTabChange}
                  actions={
                    searchbarPlacement === 'bottomLeft' ? (
                      <Stack direction="row" gap={1} alignItems="center">
                        {actions}
                        <Box>
                          {beforeSearch}
                          {/* Search bar */}
                          {!!searchProps && <SearchBar {...searchProps} />}
                          {afterSearch}
                        </Box>
                      </Stack>
                    ) : (
                      actions
                    )
                  }
                  rightSection={
                    searchbarPlacement === 'bottomRight' ? (
                      <>
                        {beforeSearch}
                        {/* Search bar */}
                        {!!searchProps && <SearchBar {...searchProps} />}
                        {afterSearch}
                      </>
                    ) : (
                      topRight
                    )
                  }
                />
              </Stack>
            </Stack>
            <Box sx={{ width: '100%', paddingTop: showFixedHeader ? 1 : 0 }}>{afterActions}</Box>
          </Box>
        </>
      )}
      {grid && (
        <Box
          sx={(theme) => ({
            margin: theme.spacing(-11, 'auto', 0),
            position: 'relative',
            [theme.breakpoints.up('md')]: {
              padding: theme.spacing(0, 8),
            },
            [theme.breakpoints.down('md')]: {
              marginTop: 0,
            },
          })}
        >
          <Box
            sx={(theme) => ({
              marginBottom: theme.spacing(2),
            })}
          >
            {grid}
          </Box>
        </Box>
      )}
      {HeroList && (
        <Box
          sx={(theme) => ({
            padding: theme.spacing(0, 12),
            marginTop: theme.spacing(2),
            [theme.breakpoints.down('md')]: {
              padding: 0,
              margin: 0,
            },
          })}
        >
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.common.white,
              borderRadius: theme.spacing(1),
              marginBottom: theme.spacing(2),
            })}
          >
            <HeroList />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Hero;
