import { AppBar, Box, Button, IconButton, Stack, SxProps, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { ReactNode, useRef, useState } from 'react';
import { MdArrowBack, MdArrowBackIos, MdSearch } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { getRoutePrefix } from 'services/theming';
import { BaseTheme } from 'config/base-theme';
import useIsDarkMode from 'hooks/useIsDarkMode';
import useIsViewport from 'hooks/useIsViewport';
import { hasRobotToken } from 'utils/hasRobotToken';
import { isIOS } from 'utils/is-ios';
import HelpActions from './HelpActions';
import ProfileConfigButton from './ProfileConfig/ProfileConfigButton';
import ProfileSwitch from './ProfileSwitch';
import SearchBar, { SearchbarProps } from './SearchBar';

interface MobileHeaderProps {
  title?: ReactNode;
  sx?: SxProps<BaseTheme>;
  children?: ReactNode;
  actions?: ReactNode;
  showSearch?: boolean;
  hasBackButton?: boolean;
  className?: string;
  returnPath?: string;
  childrenPadding?: boolean;
  alwaysShowSearch?: boolean;
  searchProps?: SearchbarProps;
}

const MobileHeader = ({
  title,
  sx,
  children,
  showSearch = true,
  className = '',
  actions,
  hasBackButton = true,
  returnPath,
  childrenPadding = true,
  alwaysShowSearch = false,
  searchProps = undefined,
}: MobileHeaderProps) => {
  const isPrint = useMediaQuery('@media print');
  const isRobot = hasRobotToken();
  const isDarkMode = useIsDarkMode();
  const navigate = useNavigate();
  const prefix = getRoutePrefix();
  const [showSearchInput, setShowSearchInput] = useState(alwaysShowSearch);
  const isSearchable = !!searchProps?.onChange && showSearch;
  const isIOSDevice = isIOS();
  const headerRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useIsViewport('md');

  const handleBack = () => {
    if (returnPath) {
      navigate(returnPath, { replace: true });
      return;
    }
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate(prefix || '/');
    }
  };

  if (isRobot || isPrint) return null;

  const handleSetInputFalse = () => {
    if (!alwaysShowSearch) setShowSearchInput(false);
  };

  if (!isMobile) {
    return null;
  }
  return (
    <>
      <Box
        ref={headerRef}
        className={className?.trim()}
        sx={(theme) => ({
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 100,
          width: '100vw',
          marginBottom: theme.spacing(1),
          [theme.breakpoints.up('md')]: {
            display: 'none',
          },
        })}
      >
        <Stack
          direction="row"
          className={className?.trim()}
          sx={{
            textAlign: 'left',
            width: '100%',
            borderBottom: !!children || isDarkMode ? 'none' : '1px solid rgba(0, 0, 0, 0.12)',
            ...{ sx },
          }}
        >
          <AppBar
            enableColorOnDark
            position="static"
            sx={(theme) => ({
              backgroundColor: isDarkMode ? theme.palette.primary.main : theme.palette.common.white,
              paddingBottom: children && childrenPadding ? theme.spacing(1) : 0,
              borderRadius: children ? theme.spacing(0, 0, 1.5, 1.5) : 0,
            })}
          >
            <Toolbar
              sx={(theme) => ({
                gap: 2,
                color: isDarkMode ? theme.palette.common.white : theme.palette.primary.main,
                backgroundColor: isDarkMode ? theme.palette.primary.main : theme.palette.common.white,
                paddingRight: theme.spacing(1),
                borderRadius: children ? theme.spacing(0, 0, 1.5, 1.5) : 0,
              })}
            >
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  minHeight: theme.spacing(6),
                  width: '100%',
                })}
              >
                {showSearchInput ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={1}
                    sx={{ width: '100%' }}
                  >
                    {alwaysShowSearch && <ProfileSwitch isButton />}
                    {searchProps && (
                      <SearchBar
                        {...searchProps}
                        sx={{ flexGrow: 1 }}
                        value={searchProps?.value ?? ''}
                        onChange={(text) => {
                          if (text.length === 0) handleSetInputFalse();
                          if (searchProps?.onChange) searchProps.onChange(text);
                        }}
                        onClose={() => handleSetInputFalse}
                        chips={{
                          data: searchProps.chips?.data ?? [],
                          onClose: () => {
                            handleSetInputFalse();
                            searchProps.chips?.onClose && searchProps.chips.onClose();
                          },
                        }}
                        showClear={!alwaysShowSearch}
                      />
                    )}
                  </Stack>
                ) : (
                  <>
                    {title && !hasBackButton ? (
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                        <ProfileSwitch isButton />
                        <Box sx={{ textAlign: 'center' }}>{title}</Box>
                      </Box>
                    ) : (
                      <>
                        <Button
                          sx={(theme) => ({
                            color: 'inherit',
                            background: 'transparent',
                            display: 'flex',
                            border: 'none',
                            alignItems: 'center',
                            height: '100%',
                            padding: theme.spacing(0.5, 2, 0.5, 0),
                            cursor: 'pointer',
                            gap: theme.spacing(0.25),
                            minWidth: 72,
                          })}
                          onClick={handleBack}
                        >
                          {isIOSDevice ? <MdArrowBackIos size={20} /> : <MdArrowBack size={20} />}
                          <Typography variant="body1">Back</Typography>
                        </Button>
                        {title && <Box sx={{ textAlign: 'center' }}>{title}</Box>}
                      </>
                    )}

                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                      sx={(theme) => ({
                        minWidth: 72,
                        gap: theme.spacing(1),
                      })}
                    >
                      {actions}
                      <ProfileConfigButton icon />
                      {isSearchable && (
                        <IconButton
                          size="small"
                          aria-label="Search"
                          color="inherit"
                          onClick={() => setShowSearchInput(true)}
                        >
                          <MdSearch size={24} />
                        </IconButton>
                      )}
                      <HelpActions />
                    </Stack>
                  </>
                )}
              </Box>
            </Toolbar>
            {children}
          </AppBar>
        </Stack>
      </Box>
      <Box sx={{ height: (headerRef?.current?.clientHeight ?? 48) + 8 }} />
    </>
  );
};

export default MobileHeader;
