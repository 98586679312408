import { Navigate } from 'react-router-dom';
import { getRoutePrefix } from 'services/theming';
import useUser from 'hooks/useUser';

const HomeRedirect = () => {
  const { isBuyer } = useUser();
  const prefix = getRoutePrefix();

  if (isBuyer) {
    return <Navigate to={`${prefix}/market`} replace />;
  }
  return <Navigate to={`${prefix}/shipments`} replace />;
};

export default HomeRedirect;
