import lazy from '@fatso83/retry-dynamic-import/react-lazy';
import { Suspense } from 'react';

const FileViewer = lazy(() => import('./FileViewer'));

interface FilesProps {
  files: string[];
}

const Files = ({ files }: FilesProps) => {
  return (
    <Suspense fallback={null}>
      {files.map((src, index) => (
        <FileViewer key={src} src={src}>
          File {index + 1}
        </FileViewer>
      ))}
    </Suspense>
  );
};

export default Files;
