import lazy from '@fatso83/retry-dynamic-import/react-lazy';
import { ErrorBoundary } from '@sentry/react';
import { Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { getPrefixedRoute, getRoutePrefix } from 'services/theming';
import { DelayedFallback } from 'components/DelayedFallback';
import HomeRedirect from 'components/HomeRedirect';
import OnlineStatusAlert from 'components/OnlineStatusAlert';
import { RequiresAuth } from 'components/RequiresAuth';
import { RouteAccess } from 'components/RouteAccess';
import Screen from 'components/Screen';
import { WhiteLabellingProvider } from 'components/WhiteLabellingProvider';
import { isSecurityEnabled } from 'config/features';
import { Permission, Scope } from 'hooks/useAccess';
import { useFeatures } from 'hooks/useFeatures';
import useIsFreshmarkProfile from 'hooks/useIsFreshmarkProfile';
import { ProfileConfigProvider } from 'hooks/useProfileConfig';
import { ReportsProvider } from 'hooks/useReports';
import useRobotToken from 'hooks/useRobotToken';
import useRouteAnalytics from 'hooks/useRouteAnalytics';
import { ShipmentsProvider } from 'hooks/useShipments';

const SourceDoc = lazy(() => import('screens/SourceDoc/SourceDoc'));
const SourceDocNew = lazy(() => import('screens/SourceDoc/SourceDocNew'));
const Shipments = lazy(() => import('screens/Shipments/Shipments'));
const QualityReport = lazy(() => import('screens/QualityReport/QualityReport'));
const ReportsNew = lazy(() => import('screens/Reports/ReportsNew'));
const Products = lazy(() => import('screens/Products/Products'));
const BuyersMarket = lazy(() => import('screens/BuyersMarket/BuyersMarket'));
const BuyersMarketCheckout = lazy(() => import('screens/Checkout/Checkout'));
const Explore = lazy(() => import('screens/Explore/Explore'));
const Customers = lazy(() => import('screens/Customers/Customers'));
const FormScreen = lazy(() => import('screens/FormScreen'));
const SellerLocations = lazy(() => import('screens/Seller/SellerLocations'));
const EditProfile = lazy(() => import('screens/UserSettings/EditProfile'));
const UserProfiles = lazy(() => import('screens/UserSettings/UserProfiles'));
const Onboard = lazy(() => import('screens/UserSettings/Onboard'));
const Chat = lazy(() => import('screens/Chat/Chat'));
const ChepLocations = lazy(() => import('screens/Administration/ChepLocations'));
const Login = lazy(() => import('screens/Auth/Login'));
const LoginNew = lazy(() => import('screens/Auth/LoginNew'));
const Landing = lazy(() => import('screens/Auth/Landing'));
const SignUp = lazy(() => import('screens/Auth/SignUp'));
const SeasonPlanner = lazy(() => import('screens/SeasonPlanner/SeasonPlanner'));
const Finance = lazy(() => import('screens/Finance/Finance'));
const MarketBalance = lazy(() => import('screens/Finance/MarketBalance'));
const MarketExport = lazy(() => import('screens/Finance/MarketExport'));
const MarketOrderBalances = lazy(() => import('screens/Finance/MarketOrderBalances'));
const MarketPalletDeposits = lazy(() => import('screens/Finance/MarketPalletDeposits'));
const MarketReconList = lazy(() => import('screens/Finance/MarketReconList'));
const XeroExport = lazy(() => import('screens/Finance/XeroExport'));
const SageExport = lazy(() => import('screens/Finance/SageExport'));
const EditShipment = lazy(() => import('screens/Shipments/EditShipment'));
const EditShipmentWrapper = lazy(() => import('screens/Shipments/EditShipmentWrapper'));
const Marketing = lazy(() => import('screens/Marketing/Marketing'));
const Posts = lazy(() => import('screens/Marketing/Posts'));
const PriceMailer = lazy(() => import('screens/Marketing/PriceMailer'));
const Notifications = lazy(() => import('screens/Notifications/Notifications'));
const Hubs = lazy(() => import('screens/Administration/Hubs'));
const AdminProductsNew = lazy(() => import('screens/Administration/AdminProductsNew'));
const Administration = lazy(() => import('screens/Administration/Administration'));
const CaseTypes = lazy(() => import('screens/Administration/CaseTypes'));
const Discounts = lazy(() => import('screens/Administration/Discounts'));
const Locations = lazy(() => import('screens/Administration/Locations'));
const RouteRates = lazy(() => import('screens/Administration/RouteRates'));
const AdminRoutes = lazy(() => import('screens/Administration/Routes'));
const TruckTypes = lazy(() => import('screens/Administration/TruckTypes'));
const AccountingDoc = lazy(() => import('screens/Accounts/AccountingDoc'));
const Account = lazy(() => import('screens/Accounts/Account'));
const Accounts = lazy(() => import('screens/Accounts/Accounts'));
const BuyerProductsNew = lazy(() => import('screens/Buyer/BuyerProductsNew'));
const CaseTransfers = lazy(() => import('screens/Buyer/CaseTransfers'));
const NotificationsDetail = lazy(() => import('screens/Notifications/NotificationsDetail'));
const OrderEditHistory = lazy(() => import('screens/Orders/OrderEditHistory'));
const UserSettingsProfile = lazy(() => import('screens/UserSettings/Profile'));
const User = lazy(() => import('screens/User/User'));
const SellerProductDetails = lazy(() => import('screens/Seller/SellerProductDetails'));
const LogisticsDoc = lazy(() => import('screens/Orders/LogisticsDoc'));
const ShipmentTransactions = lazy(() => import('screens/Shipments/ShipmentTransactions'));
const Debug = lazy(() => import('screens/Debug/Debug'));

const LOAD_FAILED_ERRORS = [
  'Failed to fetch',
  'Importing a module script failed',
  "'text/html' is not a valid JavaScript MIME type",
  'error loading dynamically imported module',
  'Load failed',
];
const SubRoutes = () => {
  /** Initiate robot token validation */
  useRobotToken();
  useRouteAnalytics();
  const prefix = getRoutePrefix();

  return (
    <>
      <ErrorBoundary
        onError={(error) => {
          LOAD_FAILED_ERRORS.forEach((message) => {
            if (error.message.includes(message)) {
              window.location.reload();
              return;
            }
          });
        }}
      >
        <WhiteLabellingProvider>
          <ProfileConfigProvider>
            {isSecurityEnabled ? (
              <Screen>
                <Suspense fallback={<DelayedFallback />}>
                  {/* Authenticated provider routes */}
                  <WhiteLabellingRoutes match={prefix} />
                </Suspense>
              </Screen>
            ) : (
              <Suspense fallback={<DelayedFallback />}>
                {/* Authenticated provider routes */}
                <WhiteLabellingRoutes match={prefix} />
              </Suspense>
            )}
          </ProfileConfigProvider>
        </WhiteLabellingProvider>
      </ErrorBoundary>
      <OnlineStatusAlert />
    </>
  );
};

const WhiteLabellingRoutes = ({ match }: { match: string | null }) => {
  const { isEnabled } = useFeatures();
  const isSeasonPlannerEnabled = useIsFreshmarkProfile();
  const isQualityControlEnabled = isEnabled('QualityControl');
  const isSourceDocsEnabled = isEnabled('SourceDocs');
  const isProductRequestEnabled = isEnabled('ProductRequest');

  return (
    <Routes>
      <Route path={`${match}/*`}>
        {isSecurityEnabled && <Route index element={<Landing />} />}
        <Route path={`login`} element={isSecurityEnabled ? <LoginNew /> : <Login />} />
        <Route path={`signup`} element={<SignUp />} />
      </Route>

      <Route path={`${match}/*`} element={<RequiresAuth allowRobot />}>
        <Route path={`sourceDoc`} element={isSourceDocsEnabled ? <SourceDocNew /> : <SourceDoc />} />
        <Route path={`accountingDoc`} element={<AccountingDoc />} />
        <Route path={`logisticsDoc`} element={<LogisticsDoc />} />
        {isQualityControlEnabled && <Route path={`quality-report/:id/:printerFriendly`} element={<QualityReport />} />}
      </Route>

      <Route path={`${match}/*`} element={<RequiresAuth />}>
        <Route index element={<HomeRedirect />} />
        <Route path={`index.html`} element={<HomeRedirect />} />

        <Route path={''} element={isSecurityEnabled ? <Outlet /> : <Screen />}>
          <Route path={`seller-locations`} element={<SellerLocations />} />
          <Route path={`hubs`} element={<Hubs />} />
          <Route path={`chat`} element={<Chat />} />
          <Route path={`locations`} element={<Locations />} />
          <Route
            path={`chep-locations`}
            element={
              <RouteAccess permission={Permission.CHEPLocations} scope={Scope.Read}>
                <ChepLocations />
              </RouteAccess>
            }
          />
          <Route path={`checkout`} element={<BuyersMarketCheckout />} />
          <Route element={<RouteAccess permission={Permission.Market} scope={Scope.Read} />}>
            <Route path={`market`} element={<BuyersMarket />} />
          </Route>
          <Route path={`seller-product-details`} element={<SellerProductDetails />} />
          <Route path={`discounts`} element={<Discounts />} />
          <Route path={`dashboard-posts`} element={<Posts />} />
          <Route path={`admin/products`} element={<AdminProductsNew />} />
          <Route path={`buyer/products`} element={<BuyerProductsNew />} />
          <Route path={`route-rates`} element={<RouteRates />} />
          <Route path={`routes`} element={<AdminRoutes />} />
          <Route path={`case-types`} element={<CaseTypes />} />
          <Route path={`truck-types`} element={<TruckTypes />} />
          <Route path={`market-balance`} element={<MarketBalance />} />
          <Route path={`market-reconlist`} element={<MarketReconList />} />
          <Route path={`market-orders`} element={<MarketOrderBalances />} />
          <Route path={`market-pallets`} element={<MarketPalletDeposits />} />
          <Route path={`market-export`} element={<MarketExport />} />
          <Route path={`xero-export`} element={<XeroExport />} />
          <Route path={`sage-export`} element={<SageExport />} />
          <Route path={`pallets`} element={<CaseTransfers />} />
          <Route path={`profile/administration`} element={<Administration />} />
          <Route
            path={`profile/marketing`}
            element={
              <RouteAccess permission={Permission.MarketingTools} scope={Scope.Read}>
                <Marketing />
              </RouteAccess>
            }
          />
          <Route
            path={`profile/finance`}
            element={
              <RouteAccess permission={Permission.Finance} scope={Scope.Read}>
                <Finance />
              </RouteAccess>
            }
          />
          <Route path={`profile/user-profiles`} element={<UserProfiles />} />
          <Route path={`profile/user`} element={<User />} />
          <Route
            path={'reports'}
            element={
              <ReportsProvider>
                <ReportsNew />
              </ReportsProvider>
            }
          />
          <Route path={`profile/:id/:type`} element={<FormScreen />} />
          <Route path={`explore`} element={<Explore />} />
          <Route
            path={'*'}
            element={
              <ShipmentsProvider>
                <Outlet />
              </ShipmentsProvider>
            }
          >
            <Route path={`shipment/inbound/:id/:intent?`} element={<EditShipment />} />
            <Route path={`shipment/outbound/:id/:intent?`} element={<EditShipment />} />
            <Route path={`shipment/finance/:id/:intent?`} element={<EditShipment />} />
            <Route path={`shipment/direct/:id/:intent?`} element={<EditShipment />} />
            <Route path={`shipment/documents/:id/:intent?`} element={<EditShipment />} />
            <Route path={`shipment/upcoming/:id/:intent?`} element={<EditShipment />} />
            <Route
              path={`shipment/:id/:intent?`}
              element={isProductRequestEnabled ? <EditShipmentWrapper /> : <EditShipment />}
            />
            <Route path={`shipments`} element={<Shipments />} />
          </Route>
          <Route path={`shipment/history/:id`} element={<OrderEditHistory />} />

          <Route path={`new/shipment-transactions/:shipmentId`} element={<ShipmentTransactions />} />
          <Route path={`form`} element={<FormScreen />} />
          {isQualityControlEnabled && <Route path={`quality-report/:id`} element={<QualityReport />} />}
          <Route path={`supply`} element={<Navigate to={`seller/products`} replace />} />
          <Route path={`pricing`} element={<Navigate to={`seller/products`} replace />} />
          <Route path={`seller/products`} element={<Products />} />
          {isSeasonPlannerEnabled && <Route path={`season-planner`} element={<SeasonPlanner />} />}
          <Route path={`new/accounts`} element={<Accounts />} />
          <Route path={`new/account`} element={<Account />} />
          <Route path={`edit/profile/:profileId`} element={<EditProfile />} />
          <Route path={`new/profile`} element={<UserSettingsProfile />} />
          <Route path={`price-mailer`} element={<PriceMailer />} />
          <Route
            path={`customers`}
            element={
              <RouteAccess permission={Permission.Customers} scope={Scope.Read}>
                <Customers />
              </RouteAccess>
            }
          />
          <Route path={`onboard`} element={<Onboard />} />
          <Route path={`new`} element={<Navigate to={getPrefixedRoute('/shipments')} replace />} />
          <Route path={`notifications/*`} element={<Notifications />} />
          <Route path={`notifications/:type`} element={<NotificationsDetail />} />
          <Route path={`debug`} element={<Debug />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default SubRoutes;
