import { Box, IconButton, Stack, Typography } from '@mui/material';
import { FaEye } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { FileMetaData } from './ImageUploadField';

export const ImageUploadPreview = ({
  fileSRC,
  fileMetaData,
  onRemove,
  onClick,
}: {
  fileSRC: string;
  fileMetaData: FileMetaData;
  onRemove: (url: string) => void;
  onClick: (src: string) => void;
}) => {
  if (!fileSRC || fileSRC?.length === 0) return null;
  return (
    <Stack
      direction={'row'}
      gap={1}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={(theme) => ({
        marginTop: theme.spacing(1),
      })}
    >
      <Stack direction={'row'} gap={1} onClick={() => onClick(fileSRC)}>
        <Box
          component="img"
          src={fileSRC}
          loading="eager"
          sx={(theme) => ({
            minWidth: theme.spacing(8),
            maxWidth: theme.spacing(8),
            height: theme.spacing(8),
            objectFit: 'cover',
            borderRadius: theme.spacing(1),
            opacity: 0.3,
          })}
        />
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          sx={(theme) => ({
            minWidth: theme.spacing(8),
            maxWidth: theme.spacing(8),
            height: theme.spacing(8),
            marginLeft: theme.spacing(-9),
            borderRadius: theme.spacing(1),
          })}
        >
          <FaEye />
        </Stack>
        <Stack sx={{ justifyContent: 'center' }}>
          <Typography>{fileMetaData.name}</Typography>
          <Typography variant={'body2'} sx={(theme) => ({ color: theme.palette.grey[700] })}>
            {fileMetaData.size}
          </Typography>
        </Stack>
      </Stack>
      <IconButton color="error" sx={{ height: 'fit-content' }} onClick={() => onRemove(fileSRC)}>
        <MdClose size={14} />
      </IconButton>
    </Stack>
  );
};

export default ImageUploadPreview;
