import lazy from '@fatso83/retry-dynamic-import/react-lazy';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  TextField,
  SxProps,
  Switch,
  InputAdornment,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Suspense } from 'react';
import { DEFAULT_STRING_DELIMITER } from 'services/api-constants';
import { BaseTheme } from 'config/base-theme';
import { useFeatures } from 'hooks/useFeatures';
import { Field, InputType } from 'generated/graphql';
import FileUploader from './FileUploader';
import ImageUploadField from './ImageUploader/ImageUploadField';
import MultiSlider from './MultiSlider';
import NumberField from './NumberField';
import NumberFormatRands from './NumberFormatRands';
import PasswordTextfield from './PasswordTextField';

const CalculatedField = lazy(() => import('./CalculatedField'));

const DynamicInputField = ({
  field,
  autoFocus,
  onChange,
  className,
  sx,
  disabled = false,
  allFields,
  isHorizontal = false,
}: {
  field: Field;
  className?: string;
  sx?: SxProps<BaseTheme>;
  autoFocus?: boolean;
  onChange(value: string, values?: string[]): void;
  allFields: Field[];
  disabled?: boolean;
  isHorizontal?: boolean;
}) => {
  const { isEnabled } = useFeatures();
  const isProductRequestEnabled = isEnabled('ProductRequest');

  const isDisabled = !!(!field || field.disabled || disabled);
  const isReadOnly = !!field.readOnly;

  switch (field.type) {
    case InputType.Hidden:
      return null;
    case InputType.Checkbox:
      return (
        <FormControlLabel
          className={className}
          sx={sx}
          control={
            <Checkbox
              readOnly={isReadOnly}
              color="secondary"
              disabled={isDisabled}
              checked={field.value === 'on'}
              onChange={(event) => {
                const val = event.target.checked ? 'on' : '';
                onChange(val);
              }}
              name={field.label}
            />
          }
          label={field.label}
        />
      );
    case InputType.Switch:
      return (
        <FormControlLabel
          className={className}
          sx={sx}
          control={
            <Switch
              color="secondary"
              readOnly={isReadOnly}
              disabled={isDisabled}
              checked={field.value === 'on'}
              onChange={(event) => {
                const val = event.target.checked ? 'on' : '';
                onChange(val);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={field.label}
        />
      );
    case InputType.Select:
      return (
        <FormControl className={className} sx={sx} variant="outlined" margin="dense" fullWidth>
          <InputLabel id={`${field.id}-label`}>{field.label}</InputLabel>
          <Select
            native
            readOnly={isReadOnly}
            labelId={`${field.id}-label`}
            id={field.id.toString()}
            label={field.label}
            value={field.value}
            disabled={isDisabled}
            onChange={(event) => {
              const val = event.target.value as string;
              onChange(val);
            }}
          >
            <option value="" aria-label="None" />
            {field.selectOptions.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </Select>
        </FormControl>
      );
    case InputType.Currency:
      return (
        <TextField
          key={`${field.id}`}
          sx={sx}
          className={className}
          variant="outlined"
          margin="dense"
          autoFocus={autoFocus}
          disabled={isDisabled}
          fullWidth
          id={field.id.toString()}
          label={field.label}
          value={field.value}
          onChange={(event) => onChange(event.target.value)}
          InputProps={{
            inputComponent: NumberFormatRands as any,
          }}
        />
      );
    case InputType.Multiselect:
      return (
        <Autocomplete
          multiple
          readOnly={isReadOnly}
          className={className}
          sx={{ marginBottom: 1, maxWidth: 600 }}
          id={field.id.toString()}
          options={field.selectOptions}
          getOptionLabel={(o) => o.label}
          disabled={isDisabled}
          filterSelectedOptions
          value={field.selectOptions.filter(
            (o) => o.value && field.value.split(DEFAULT_STRING_DELIMITER).includes(o.value),
          )}
          onChange={(_, options) => {
            const values = options.map((option) => option.value);
            const value = values.join(DEFAULT_STRING_DELIMITER);
            onChange(value, values);
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            );
          }}
          renderInput={(props) => (
            <TextField
              {...props}
              variant="outlined"
              margin="dense"
              autoFocus={autoFocus}
              fullWidth
              label={field.label}
            />
          )}
        />
      );
    case InputType.Autocomplete:
      return (
        <Autocomplete
          className={className}
          sx={sx}
          readOnly={isReadOnly}
          id={field.id.toString()}
          options={field.selectOptions}
          getOptionLabel={(o) => o.label}
          disabled={isDisabled}
          value={field.selectOptions.find((o) => o.value === field.value) ?? null}
          onChange={(_, newSelected) => {
            const val = newSelected?.value;
            onChange(val || '');
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            );
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              variant="outlined"
              margin="dense"
              autoFocus={autoFocus}
              fullWidth
              label={field.label}
            />
          )}
        />
      );
    case InputType.Date:
      return (
        <TextField
          className={className}
          sx={sx}
          variant="outlined"
          margin="dense"
          autoFocus={autoFocus}
          fullWidth
          id={field.id.toString()}
          type={field.type}
          label={field.label}
          inputProps={{ readOnly: isReadOnly }}
          disabled={isDisabled}
          InputLabelProps={{
            shrink: true,
          }}
          value={field.value}
          onChange={(event) => onChange(event.target.value || '')}
        />
      );
    case InputType.Images:
    case InputType.Files:
      if (isProductRequestEnabled)
        return (
          <ImageUploadField
            files={field.values?.length ? field.values : [field.value].filter(Boolean)}
            onChange={(values) => {
              onChange('', values);
            }}
            isHorizontal={isHorizontal}
          />
        );
      else
        return (
          <FileUploader
            label={field.label}
            files={field.values?.length ? field.values : [field.value].filter(Boolean)}
            onChange={(values) => {
              onChange('', values);
            }}
          />
        );
    case InputType.Multiline:
      return (
        <TextField
          className={className}
          sx={sx}
          variant="outlined"
          margin="dense"
          disabled={isDisabled}
          autoFocus={autoFocus}
          inputProps={{ readOnly: isReadOnly }}
          fullWidth
          id={field.id.toString()}
          type={field.type}
          label={field.label}
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          rows={4}
          value={field.value}
          onChange={(event) => onChange(event.target.value || '')}
        />
      );
    case InputType.Number:
      return (
        <NumberField
          className={className}
          sx={sx}
          inputProps={{ readOnly: isReadOnly }}
          disabled={isDisabled}
          margin="dense"
          autoFocus={autoFocus}
          fullWidth
          id={field.id.toString()}
          label={field.label}
          value={field.value}
          onChange={(_, value) => onChange(value || '')}
        />
      );
    case InputType.Calculated:
      return (
        <Suspense fallback={null}>
          <CalculatedField
            label={field.label}
            value={field.values.length > 0 ? field.values[0] : field.value}
            fields={allFields}
          />
        </Suspense>
      );
    case InputType.Slider:
      return (
        <MultiSlider selectOptions={field.selectOptions.slice().reverse()} label={field.label} onChange={onChange} />
      );
    case InputType.Percentage:
      return (
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id={field.id.toString()}
          inputProps={{ readOnly: isReadOnly }}
          disabled={isDisabled}
          type={field.type}
          label={field.label}
          InputProps={{
            type: 'number',
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          defaultValue={Number((Number.parseFloat(field.value) * 100).toFixed(3))}
          onChange={(event) => {
            const value = Number.parseFloat(event.target.value) / 100;
            if (!Number.isNaN(value)) {
              onChange((Number(event.target.value) / 100).toString());
            }
          }}
        />
      );
    case InputType.Password:
      return <PasswordTextfield field={field} isDisabled={isDisabled} sx={sx} onChange={onChange} />;
    default:
      return (
        <TextField
          variant="outlined"
          className={className}
          sx={sx}
          margin="dense"
          autoFocus={autoFocus}
          disabled={isDisabled}
          fullWidth
          inputProps={{ readOnly: isReadOnly }}
          id={field.id.toString()}
          type={field.type}
          label={field.label}
          value={field.value}
          onChange={(event) => onChange(event.target.value || '')}
        />
      );
  }
};

export default DynamicInputField;
