import dayjs, { Dayjs } from 'dayjs';
import { createContext, ReactNode, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UseQueryState } from 'urql';
import ErrorSnackbar from 'components/ErrorSnackbar';
import { Filter, FilterInput, FilterType, ReportsScreenQuery, useReportsScreenQuery } from 'generated/graphql';
import useDebouncedFunc from './useDebouncedFunc';
import useNumberParams from './useNumberParams';

const DEBOUNCE_TIME = 800;

export enum WeekToMonthOptions {
  WEEK_TO_DATE = 'Last 7 days',
  MONTH_TO_DATE = 'Last 30 days',
}

export enum ViewOptions {
  GRAPH = 'Graph',
  TABLE = 'Table',
}

interface ReportsContextProps {
  reportsResult: UseQueryState<ReportsScreenQuery>;
  reportsFetching?: boolean;
  startDate: Dayjs;
  setStartDate: (startDate: Dayjs) => void;
  endDate: Dayjs;
  setEndDate: (endDate: Dayjs) => void;
  filters: FilterInput[];
  setFilters: (filters: FilterInput[]) => void;
  productFilters?: Filter[];
  setProductFilters: (productFilters: Filter[]) => void;
  shipmentReference?: string;
  setShipmentReference: (shipmentReference: string) => void;
  searchValue: string;
  setSearchValue: (search: string) => void;
  refreshReportsScreen: () => void;
  showLinkingError: boolean;
  setShowLinkingError: (showLinkingError: boolean) => void;
  productID: number;
  handleSearch: (value: string) => void;
  handleFiltersApplied: (filterInput: FilterInput[]) => void;
  filterOpen: boolean;
  setFilterOpen: (filterOpen: boolean) => void;
  openTechnoTermsDialog: boolean;
  setOpenTechnoTermsDialog: (openTechnoTermsDialog: boolean) => void;
  weekToMonthOption: WeekToMonthOptions;
  setWeekToMonthOption: (weekToMonthOption: WeekToMonthOptions) => void;
  handleWeekToMonthSelected: (value: string) => void;
  showPricingGraphs: boolean;
  setShowPricingGraphs: (showPricingGraphs: boolean) => void;
  viewPricingCalendar: boolean;
  setViewPricingCalendar: (viewPricingCalendar: boolean) => void;
  isInitialFetching: boolean;
}

const Context = createContext<ReportsContextProps | undefined>(undefined);

const getDefaultFilters = (filters: Filter[] | null = null): FilterInput[] => {
  if (filters) {
    return filters.map((filter: Filter) => ({
      type: filter.type,
      selectedIDs: [],
    }));
  }
  return Object.values(FilterType).map((type) => ({
    type,
    selectedIDs: [],
  }));
};

export const ReportsProvider = ({ children }: { children: ReactNode }) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(dayjs());
  const [searchValue, setSearchValue] = useState('');
  const [filteredSearchValue, setFilteredSearchValue] = useState<string>('');
  const [getNumberParam] = useNumberParams();
  const productID = getNumberParam('productID') ?? 0;
  const [filters, setFilters] = useState<FilterInput[]>(getDefaultFilters(null));
  const [productFilters, setProductFilters] = useState<Filter[]>();
  const [shipmentReference, setShipmentReference] = useState('');
  const [showLinkingError, setShowLinkingError] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [openTechnoTermsDialog, setOpenTechnoTermsDialog] = useState(false);

  // States specific to pricing dashboard
  const [weekToMonthOption, setWeekToMonthOption] = useState(WeekToMonthOptions.WEEK_TO_DATE);
  const [showPricingGraphs, setShowPricingGraphs] = useState(false);
  const [viewPricingCalendar, setViewPricingCalendar] = useState(false);

  const [reportsResult, refreshReportsScreen] = useReportsScreenQuery({
    variables: {
      input: {
        start: startDate,
        end: endDate,
        search: filteredSearchValue ? filteredSearchValue.toLowerCase() : '',
        productID,
        filters,
        shipmentReference,
      },
    },
    requestPolicy: 'network-only',
  });

  const reportsFetching = reportsResult.fetching || reportsResult.stale;
  const error = reportsResult.error;

  useEffect(() => {
    if (error) setErrorMessage(error.message);
  }, [error]);

  const handleFilter = useCallback(
    (value: string = '', filterInput = filters) => {
      if (filterInput) {
        setFilters(filterInput);
        value = filterInput.reduce((acc: string, curr: FilterInput) => {
          const filter = productFilters?.find((filter) => filter.type === curr.type);

          if (!filter) {
            return acc;
          }
          const selectedIDs = curr.selectedIDs.map((id) => Number(id));
          const appendType = curr.type !== FilterType.Class;
          const isDescription = curr.type === FilterType.Size;
          const typeLabel = filter.type.toLowerCase().slice(0, 3);
          const filterString = filter.options
            .filter(
              (option) =>
                selectedIDs.includes(Number(option.id)) &&
                (filter.type !== FilterType.Variety ||
                  (filter.type === FilterType.Variety && option.description !== '')),
            )
            .map((option) => {
              const label = isDescription
                ? option.description === 'No Size'
                  ? option.description.replace(' ', '')
                  : option.description
                : option.label;
              return appendType ? `${typeLabel}${label}` : label;
            });

          return `${acc.length > 0 ? acc + ' ' : acc}${filterString.toString()}`;
        }, value);
      }

      if (value !== searchValue) {
        setFilteredSearchValue(value);
        refreshReportsScreen({
          start: startDate.toISOString(),
          end: endDate.toISOString(),
          filters: filters,
          productID: 0,
          search: value,
        });
      }
    },
    [productFilters, searchValue, startDate, endDate, filters, setFilteredSearchValue, refreshReportsScreen],
  );

  const handleSearch = useDebouncedFunc((value: string) => {
    handleFilter(value);
  }, DEBOUNCE_TIME);

  const handleFiltersApplied = useDebouncedFunc((filterInput: FilterInput[]) => {
    filterInput = filterInput.map((input) => ({
      type: input.type,
      selectedIDs: input.selectedIDs,
    }));
    handleFilter(searchValue, filterInput);
  }, DEBOUNCE_TIME);

  const navigate = useNavigate();
  const location = useLocation();

  const removeProductIDQueryParam = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('productID');
    searchParams.delete('cat');
    searchParams.delete('var');
    searchParams.delete('cla');
    searchParams.delete('wei');
    searchParams.delete('siz');
    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      { replace: true },
    );
  }, [navigate, location.pathname, location.search]);

  const catURLParam = getNumberParam('cat');
  const varURLParam = getNumberParam('var');
  const claURLParam = getNumberParam('cla');
  const sizURLParam = getNumberParam('siz');
  const weiURLParam = getNumberParam('wei');

  const productFilterInputs: any[] = useMemo(() => {
    return [
      { type: FilterType.Category, selectedIDs: [catURLParam ?? 0] },
      { type: FilterType.Variety, selectedIDs: [varURLParam ?? 0] },
      { type: FilterType.Class, selectedIDs: [claURLParam ?? 0] },
      { type: FilterType.Size, selectedIDs: [sizURLParam ?? 0] },
      { type: FilterType.Weight, selectedIDs: [weiURLParam ?? 0] },
    ];
  }, [catURLParam, varURLParam, claURLParam, sizURLParam, weiURLParam]);

  /*This useEffect is required for the compare button in the pricing table.
    The compare button updates the filters with the relevant params 
    and changes the pricing dash view to the graphs*/
  useEffect(
    function handlePricingTableCompare() {
      if (productID !== 0) {
        handleFilter(searchValue, productFilterInputs);
        setShowPricingGraphs(true);
        removeProductIDQueryParam();
      }
    },
    [productID, searchValue, productFilterInputs, handleFilter, removeProductIDQueryParam, getNumberParam],
  );

  const handleWeekToMonthSelected = useDebouncedFunc((value: string) => {
    setWeekToMonthOption(value as WeekToMonthOptions);
    setEndDate(dayjs());
    const timePeriod = value === WeekToMonthOptions.WEEK_TO_DATE ? 'week' : 'month';
    setStartDate(dayjs().subtract(1, timePeriod));
  }, DEBOUNCE_TIME);

  const value = useMemo(
    () => ({
      reportsResult,
      reportsFetching,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      filters,
      setFilters,
      productFilters,
      setProductFilters,
      shipmentReference,
      setShipmentReference,
      searchValue,
      setSearchValue,
      refreshReportsScreen,
      showLinkingError,
      setShowLinkingError,
      productID,
      handleSearch,
      handleFiltersApplied,
      filterOpen,
      setFilterOpen,
      openTechnoTermsDialog,
      setOpenTechnoTermsDialog,
      weekToMonthOption,
      setWeekToMonthOption,
      handleWeekToMonthSelected,
      viewPricingCalendar,
      setViewPricingCalendar,
      showPricingGraphs,
      setShowPricingGraphs,
      isInitialFetching: reportsFetching && !reportsResult.data,
    }),
    [
      reportsResult,
      reportsFetching,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      filters,
      setFilters,
      productFilters,
      setProductFilters,
      shipmentReference,
      setShipmentReference,
      searchValue,
      setSearchValue,
      refreshReportsScreen,
      showLinkingError,
      setShowLinkingError,
      productID,
      handleSearch,
      handleFiltersApplied,
      filterOpen,
      setFilterOpen,
      openTechnoTermsDialog,
      setOpenTechnoTermsDialog,
      weekToMonthOption,
      setWeekToMonthOption,
      handleWeekToMonthSelected,
      viewPricingCalendar,
      setViewPricingCalendar,
      showPricingGraphs,
      setShowPricingGraphs,
    ],
  );

  return (
    <Context.Provider value={value}>
      {children}
      <ErrorSnackbar isOpen={!!errorMessage} onClose={() => setErrorMessage(undefined)}>
        {errorMessage}
      </ErrorSnackbar>
    </Context.Provider>
  );
};

export const useReports = () => {
  const result = useContext(Context);
  if (!result) throw new Error('useReports must be used within a ReportsProvider');
  return result;
};
