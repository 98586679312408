import { devtoolsExchange } from '@urql/devtools';
import { persistedExchange } from '@urql/exchange-persisted';
import authEx from 'services/auth';
import { createClient, fetchExchange, cacheExchange as defaultCacheExchange } from 'urql';

export const makeClient = () => {
  return createClient({
    url: import.meta.env.REACT_APP_GAPI_URL!,
    requestPolicy: 'cache-and-network',
    exchanges: [
      devtoolsExchange,
      defaultCacheExchange,
      authEx,
      persistedExchange({
        preferGetForPersistedQueries: true,
      }),
      fetchExchange,
    ],
    fetchOptions: () => ({
      credentials: 'include',
      headers: { Authorization: 'unauthenticated' },
    }),
  });
};
