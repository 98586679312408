import dayjs from 'services/dayjs';
import isBetweenHours from './isBetweenHours';

export const MARKET_MORNING_START = '08:00';
export const MARKET_MORNING_END = '10:30';
export const MARKET_AFTERNOON_START = '14:00';
export const MARKET_AFTERNOON_END = '16:30';

const getMarketisOpen = () => {
  const current = dayjs();
  return (
    isBetweenHours(current, MARKET_MORNING_START, MARKET_MORNING_END) ||
    isBetweenHours(current, MARKET_AFTERNOON_START, MARKET_AFTERNOON_END)
  );
};

export default getMarketisOpen;
