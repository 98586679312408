import React, { createContext, useContext, useMemo, ReactNode } from 'react';
import { Button, useProfileSwitchOptionsQuery } from 'generated/graphql';
import { useFeatures } from './useFeatures';
import useSearch from './useSearch';
import useUser from './useUser';

interface ProfileConfigContextType {
  data?: Button;
  loading: boolean;
}

export const ProfileConfigContext = createContext<ProfileConfigContextType>({ data: undefined, loading: false });

const DEFAULT_ID = '0';

export const ProfileConfigProvider = ({ children }: { children: ReactNode }) => {
  const { isAdmin } = useUser();
  const [getParam] = useSearch();

  const profileID = getParam('profileID') ?? getParam('profile') ?? getParam('sellerUUID') ?? DEFAULT_ID;
  const { isEnabled } = useFeatures();
  const isProfileConfigEnabled = isEnabled('ProfileConfig');

  const [result] = useProfileSwitchOptionsQuery({
    variables: {
      profileID: Number(profileID),
    },
    pause: !isAdmin || profileID.toString() === DEFAULT_ID || !isProfileConfigEnabled,
  });

  const value = useMemo(
    () => ({
      data: profileID && profileID.toString() !== DEFAULT_ID ? result.data?.profileSwitchOptions : undefined,
      loading: !!result.fetching,
    }),
    [result, profileID],
  );

  return <ProfileConfigContext.Provider value={value}>{children}</ProfileConfigContext.Provider>;
};

const useProfileConfig = () => {
  const context = useContext(ProfileConfigContext);
  if (!context) {
    throw new Error('useProfileConfig must be used within a ProfileConfigProvider');
  }
  return context;
};

export default useProfileConfig;
