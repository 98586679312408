import { Box, Divider, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import { MdClose } from 'react-icons/md';
import { useFeatures } from 'hooks/useFeatures';
import { showField, showGroup, showSet } from 'utils/form';
import {
  Card,
  DescriptionVariant,
  Field,
  FieldGroup,
  FieldGroupOrientation,
  FieldSet,
  FieldSetVariant,
  Form as FormData,
  InputType,
} from 'generated/graphql';
import ActionButton from './ActionButton';
import CustomCard from './CustomCard';
import DynamicFieldGroups from './DynamicFieldGroups';
import DynamicInputField from './DynamicInputField';

const isLogoutCard = (card: Card) => {
  return card.button.value === 'Logout';
};

const FormField = ({
  field,
  autoFocus,
  allFields,
  onChange,
  disabled = false,
  isHorizontal = false,
}: {
  field: Field;
  autoFocus: boolean;
  allFields: Field[];
  disabled?: boolean;
  isHorizontal?: boolean;
  onChange: (field: Field, value: string, values?: string[]) => void;
}) => {
  if (!showField(field, allFields) || field.type === InputType.Hidden) {
    return null;
  }
  return (
    <Box sx={{ mb: 1, flex: field.type === InputType.Images ? 'none' : 1, alignContent: 'center' }}>
      <DynamicInputField
        key={field.id}
        field={field}
        disabled={disabled}
        allFields={allFields}
        autoFocus={autoFocus}
        onChange={(value, values) => onChange(field, value, values)}
        isHorizontal={isHorizontal}
      />
    </Box>
  );
};

const FormGroups = ({
  fieldGroups,
  onFieldUpdate,
  isLoading = false,
  onRefresh,
  isFormDialog = false,
  autoFocusGroup = -1,
}: {
  fieldGroups: FieldGroup[];
  isLoading?: boolean;
  isFormDialog: boolean;
  onFieldUpdate: (field: Field, value: string, values?: string[]) => void;
  onRefresh?: () => void;
  autoFocusGroup?: number;
}) => {
  const { isEnabled } = useFeatures();
  const isProductRequestEnabled = isEnabled('ProductRequest'); // TODO: Use generic flag
  if (!fieldGroups) {
    return null;
  }

  return (
    <>
      {fieldGroups.map((group, groupIndex) => {
        const firstField =
          autoFocusGroup === groupIndex ? group.fields.findIndex((field) => field.type !== InputType.Hidden) : -1;

        if (isProductRequestEnabled && !showGroup(group)) return null;
        return (
          <Box key={`${group.title}-${groupIndex}`}>
            {group.title && (
              <Typography
                sx={(theme) => ({
                  fontSize: '1rem',
                  mb: theme.spacing(0.5),
                  mr: 1,
                  fontWeight: theme.typography.fontWeightMedium,
                })}
              >
                {group.title}
              </Typography>
            )}
            <Box
              sx={
                group.orientation === FieldGroupOrientation.Horizontal
                  ? {
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      gap: 1,
                    }
                  : {}
              }
            >
              {group.fields.map((field, fieldIndex, fields) => (
                <FormField
                  key={field.id}
                  field={field}
                  autoFocus={fieldIndex === firstField}
                  allFields={fields}
                  disabled={isLoading}
                  onChange={onFieldUpdate}
                  isHorizontal={group.orientation === FieldGroupOrientation.Horizontal}
                />
              ))}
              {group.cards && group.cards.length > 0 && (
                <>
                  <Grid container gap={1} sx={{ justifyContent: 'flex-start', gap: '1.2%' }}>
                    {group.cards
                      .filter((card) => !isLogoutCard(card))
                      .map((card, index) => (
                        <Grid item key={`${card.title}-${index}`} md={isFormDialog ? 12 : 3.9} sx={{ width: '100%' }}>
                          <CustomCard key={index} data={card} isLoading={isLoading} onRefresh={onRefresh} />
                        </Grid>
                      ))}
                  </Grid>
                  {group.cards
                    .filter((card) => isLogoutCard(card))
                    .map((card, index) => (
                      <CustomCard key={index} data={card} isLoading={isLoading} onRefresh={onRefresh} />
                    ))}
                </>
              )}
            </Box>
          </Box>
        );
      })}
    </>
  );
};

const FormSets = ({
  fieldSets,
  onFieldUpdate,
  isLoading = false,
  onRefresh,
  isFormDialog,
  autoFocusSet = -1,
}: {
  fieldSets?: FieldSet[];
  isLoading?: boolean;
  isFormDialog: boolean;
  onFieldUpdate: (field: Field, value: string, values?: string[]) => void;
  onRefresh?: () => void;
  autoFocusSet?: number;
}) => {
  const { isEnabled } = useFeatures();
  const isProductRequestEnabled = isEnabled('ProductRequest'); // TODO: Use generic flag
  if (!fieldSets) {
    return null;
  }
  return (
    <>
      {fieldSets.map((fieldSet, index) => {
        const firstGroup =
          index === autoFocusSet
            ? fieldSet.groups.findIndex((group) => group.fields.some((field) => field.type !== InputType.Hidden))
            : -1;

        if (isProductRequestEnabled && !showSet(fieldSet.groups)) return null;

        return fieldSet.variant === FieldSetVariant.Dynamic ? (
          <DynamicFieldGroups key={index} fieldGroups={fieldSet.groups} onFieldUpdate={onFieldUpdate} />
        ) : (
          <>
            <Box sx={{ marginY: 1 }} key={index}>
              <FormGroups
                key={index}
                fieldGroups={fieldSet.groups}
                isLoading={isLoading}
                onFieldUpdate={onFieldUpdate}
                onRefresh={onRefresh}
                isFormDialog={isFormDialog}
                autoFocusGroup={firstGroup}
              />
            </Box>
            {index < fieldSets?.length - 1 && <Divider sx={{ borderWidth: 0.5 }} />}
          </>
        );
      })}
    </>
  );
};

const Form = ({
  form,
  hideTitle = false,
  isLoading = false,
  isFormDialog = false,
  onClose = undefined,
  onFieldUpdate,
  onRefresh,
}: {
  hideTitle?: boolean;
  form?: FormData;
  isLoading?: boolean;
  isFormDialog?: boolean;
  onClose?: () => void;
  onFieldUpdate: (field: Field, value: string, values?: string[]) => void;
  onRefresh?: () => void;
}) => {
  const theme = useTheme();

  if (!form) {
    return null;
  }

  const hasGroups = Boolean(form.fieldGroups?.length);
  const hasSets = Boolean(form.fieldSets?.length);

  const firstField = form.fields.findIndex((field) => field.type !== InputType.Hidden);
  const firstGroup = form.fieldGroups.findIndex((fieldGroup) =>
    fieldGroup.fields.some((field) => field.type !== InputType.Hidden),
  );
  const firstSet = form.fieldSets.findIndex((set) =>
    set.groups.some((group) => group.fields.some((field) => field.type !== InputType.Hidden)),
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {!hideTitle && (
          <>
            <Typography variant="h6" sx={(theme) => ({ color: theme.palette.primary.main })}>
              {form.title}
            </Typography>
            {onClose && (
              <IconButton onClick={onClose}>
                <MdClose size={24} fill={theme.palette.grey[700]} />
              </IconButton>
            )}
          </>
        )}
      </Stack>
      {form.description.length > 0 ? (
        form.descriptionVariant === DescriptionVariant.Callout ? (
          <Alert severity="success" icon={false}>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              {form.description}
            </Typography>
          </Alert>
        ) : (
          <Typography
            variant="body1"
            sx={(theme) => ({
              color: theme.palette.grey[700],
              mt: theme.spacing(1.5),
              mb: theme.spacing(2),
              fontSize: '0.875rem',
              maxWidth: onClose ? 'calc(100% - 24px)' : '100%',
            })}
          >
            {form.description}
          </Typography>
        )
      ) : null}

      {form.actionButtons.length > 0 && (
        <Stack flexDirection="row" alignItems="center" justifyContent="flex-end" gap={1}>
          {form.actionButtons.map((button, index) => (
            <ActionButton key={index} button={button} onRefresh={onRefresh} />
          ))}
        </Stack>
      )}

      {hasSets ? (
        <FormSets
          fieldSets={form.fieldSets}
          isLoading={isLoading}
          onFieldUpdate={onFieldUpdate}
          onRefresh={onRefresh}
          isFormDialog={isFormDialog}
          autoFocusSet={firstSet}
        />
      ) : hasGroups ? (
        <FormGroups
          fieldGroups={form.fieldGroups}
          isLoading={isLoading}
          onFieldUpdate={onFieldUpdate}
          isFormDialog={isFormDialog}
          autoFocusGroup={firstGroup}
        />
      ) : (
        form.fields.map((field, index, fields) => (
          <FormField
            key={field.id}
            field={field}
            autoFocus={index === firstField}
            allFields={fields}
            disabled={isLoading}
            onChange={onFieldUpdate}
          />
        ))
      )}
    </>
  );
};

export default Form;
