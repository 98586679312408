import { create } from 'zustand';
import { MarketInputNew } from 'generated/graphql';

const useBuyersMarketStateStore = create<{
  input: MarketInputNew | undefined;
  setInput: (newInput: Partial<MarketInputNew>) => void;
}>((set) => ({
  input: undefined,
  setInput: (input: Partial<MarketInputNew>) =>
    set((store) => ({
      ...store,
      input: {
        ...store.input,
        ...input,
      } as MarketInputNew,
    })),
}));

export default useBuyersMarketStateStore;
