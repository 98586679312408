import { CartGroup, CartGroupNew, Trucks } from 'generated/graphql';
import { Carts } from './useBuyersMarketCart';

const calculateTotalCases = (carts: Carts): number => {
  if (!carts) return 0;

  return carts.reduce((acc, cart) => {
    return (
      acc +
      cart.items.reduce((accItem, currentItem) => {
        return accItem + currentItem.cases;
      }, 0)
    );
  }, 0);
};

const useCartInfo = (cartGroup?: CartGroup | CartGroupNew, trucks?: Trucks, carts?: Carts) => {
  const truckType = trucks?.types?.find((t) => t.uuid === cartGroup?.truckTypeUUID);
  const openTruckCases =
    trucks?.openTrucks?.find((t) => t.truckTypeUUID === cartGroup?.truckTypeUUID)?.usedCapacity ?? 0;
  const cartGroupCases =
    (carts ? calculateTotalCases(carts) : cartGroup?.carts?.reduce((pals, cart) => pals + cart.cartCases, 0)) ?? 0;
  const truckUsedCapacity = cartGroupCases + openTruckCases;

  return { openTruckCases, cartGroupCases, truckUsedCapacity, truckType };
};

export default useCartInfo;
