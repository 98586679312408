import { NumericFormat, NumericFormatProps } from 'react-number-format';

const NumberFormatRands = ({
  inputRef,
  onChange,
  value,
  name,
  ...rest
}: {
  inputRef: (instance: NumericFormatProps | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  value: number;
  name: string;
}) => (
  <NumericFormat
    {...rest}
    inputMode="decimal"
    pattern="[0-9]*"
    value={value / 100}
    getInputRef={inputRef}
    onValueChange={(values) => {
      onChange({
        target: {
          name,
          value: Math.round(Number.parseFloat(values.value) * 100).toString(),
        },
      });
    }}
    decimalScale={2}
    thousandSeparator
    valueIsNumericString
    prefix="R"
  />
);

export default NumberFormatRands;
