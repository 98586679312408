import structuredClone from '@ungap/structured-clone';
import dayjs from 'dayjs';
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useBuyersMarketStateStore from 'store/useBuyersMarketStateStore';
import { CombinedError } from 'urql';
import ErrorSnackbar from 'components/ErrorSnackbar';
import { useWhiteLabelling } from 'components/WhiteLabellingProvider';
import { logAnalytics } from 'utils/logAnalytics';
import {
  CartGroupNew,
  CartInput,
  CartItem,
  CartItemMetricInfo,
  GroupOption,
  MarketSellerInfo,
  NotificationType,
  useBuyersMarketCartTotalsQuery,
  useBuyersMarketCartsQuery,
  useCartCheckoutMutation,
  useCartRemoveMutation,
  useCartSetItemMutation,
  useNotifyMutation,
  BuyersMarketCartTotalsQuery,
  MarketMetric,
} from 'generated/graphql';
import { CartCheckoutInput } from 'generated/graphql';
import { ShippingMethod } from '../generated/graphql';
import useDebouncedFunc from './useDebouncedFunc';
import useSearch from './useSearch';
import useUser from './useUser';
import useVersionedQuery from './useVersionedQuery';

export const DEFAULT_ADMIN_PROFILE_ID = '0';
const DEBOUNCE_TIME = 600;

export type PartialCartItem = Partial<CartItem> & {
  ripeness: string;
  unitQuantity: number;
  cases: number;
  casePack: number;
  productUUID: string;
  images: string[];
  label: string;
  unitPrice: string;
  metricInfo: CartItemMetricInfo[];
};

export type Carts = ({
  items: PartialCartItem[];
} & CartSeller)[];

export interface CartSeller {
  seller: MarketSellerInfo;
  cartCases?: number;
}

type TypeRebate = {
  __typename?: 'DocItem' | undefined;
  label: string;
  value: string;
};
const Context = createContext<{
  cartGroup?: CartGroupNew;
  carts: Carts;
  cartTotals?: BuyersMarketCartTotalsQuery['cart']['cartTotals'];
  deliveryDate: string;
  isCartsQueryPaused: boolean;
  isFetching: boolean;
  rebateAndCartsFetching: boolean;
  rebate?: TypeRebate;
  checkout: (total: number, input?: CartCheckoutInput) => Promise<any>;
  clear: () => void;
  notify: () => Promise<undefined | CombinedError>;
  refresh: () => void;
  remove: (sellerUUID: string, productUUID: string) => void;
  update: (cart: CartInput & CartSeller, item: PartialCartItem) => void;
  setDeliveryDate: (value: string) => void;
} | null>(null);

type RemoveInput = { sellerID: string; productID: string; buyerID: string };
type CartItemSeller = { items: PartialCartItem[] } & CartSeller;

export const BuyersMarketCartProvider = ({ children }: { children: ReactNode }) => {
  const { isSeller, user, isPendingReview, isRegistering, isAdmin } = useUser();
  const location = useLocation();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const buyersMarketStateStore = useBuyersMarketStateStore();
  const [carts, setCarts] = useState<({ items: PartialCartItem[] } & CartSeller)[]>([]);
  const [localVersion, setLocalVersion] = useState<number>(-1);
  const buyerID = buyersMarketStateStore.input?.buyerID;
  const isCheckout = location.pathname.includes('checkout');
  const isCartsQueryPaused =
    isSeller ||
    isPendingReview ||
    isRegistering ||
    !user?.profileId ||
    !buyerID ||
    buyerID === DEFAULT_ADMIN_PROFILE_ID;
  const [, notify] = useNotifyMutation();

  /** Cart results */
  const {
    result: cartsResult,
    refresh: handleRefreshCartsResult,
    version: queryVersion,
  } = useVersionedQuery(useBuyersMarketCartsQuery, {
    variables: {
      buyerID: buyerID,
    },
    pause: isCartsQueryPaused,
  });

  const { data, fetching: cartResultsFetching, stale: cartsResultStale } = cartsResult;
  const cartGroup = data?.cart as CartGroupNew;

  const [deliveryDate, setDeliveryDate] = useState(cartGroup?.deliveryDate);

  useEffect(() => {
    setDeliveryDate(cartGroup?.deliveryDate);
  }, [cartGroup]);
  /** Cart totals and rebate only */
  const [cartsTotalsResult, handleRefreshCartsTotalsResult] = useBuyersMarketCartTotalsQuery({
    variables: {
      buyerID: buyerID ?? DEFAULT_ADMIN_PROFILE_ID,
    },
    pause: isCartsQueryPaused || !isCheckout,
  });
  const { data: dataTotals, fetching: cartTotalsResultsFetching, stale: cartTotalsResultStale } = cartsTotalsResult;

  const [, cartSetItem] = useCartSetItemMutation();
  const [cartRemoveResults, cartRemove] = useCartRemoveMutation();
  const [cartSetItemResult, cartCheckout] = useCartCheckoutMutation();

  const { error: removeCartItemError } = cartRemoveResults;
  const { error: setCartItemError } = cartSetItemResult;

  const isFetching = cartResultsFetching || cartsResultStale || cartTotalsResultsFetching || cartTotalsResultStale;

  useEffect(() => {
    if (buyerID) {
      setCarts([]);
    }
  }, [buyerID]);

  const handleRefresh = useCallback(() => {
    handleRefreshCartsTotalsResult();
    handleRefreshCartsResult();
  }, [handleRefreshCartsResult, handleRefreshCartsTotalsResult]);

  const [getParam] = useSearch();
  const { isVillaProfile } = useWhiteLabelling();
  const cartShippingMethod = cartGroup?.shippingMethodLabel;

  const defaultShippingMethod =
    (getParam('shippingMethod') as ShippingMethod) ??
    ((cartShippingMethod?.length ?? 0) > 0
      ? cartShippingMethod
      : isVillaProfile
        ? ShippingMethod.Direct
        : ShippingMethod.Hub);

  useEffect(() => {
    if (!buyersMarketStateStore.input && !isSeller) {
      const currBuyerID = isAdmin ? getParam('buyerID') : `${user?.profileId}`;
      const queryTruckType = getParam('truckTypeUUID');

      buyersMarketStateStore.setInput({
        search: '',
        filters: [],
        truckTypeUUID: queryTruckType ?? DEFAULT_ADMIN_PROFILE_ID,
        buyerID: currBuyerID ?? DEFAULT_ADMIN_PROFILE_ID,
        method: defaultShippingMethod,
        groupOption: GroupOption.Seller,
        metric: MarketMetric.Pallets,
      });
    }
  }, [getParam, user?.profileId, user?.userType, isSeller, isAdmin, defaultShippingMethod, buyersMarketStateStore]);

  const validateCarts = () => {
    if (queryVersion > localVersion) {
      const filteredCarts = cartGroup.carts.filter((cart) => cart.itemsByType.length > 0) ?? [];
      const mappedCarts = filteredCarts.map((cart) => ({
        seller: cart.seller,
        cartCases: cart.cartCases,
        items: (
          cart.itemsByType.flatMap((itemsForType) => itemsForType.itemsByGroup.flatMap((group) => group.items)) || []
        ).sort((a, b) => (dayjs(a.created).isBefore(dayjs(b.created)) ? 1 : -1)),
      }));

      setCarts(mappedCarts);
      setLocalVersion(queryVersion);
    }
  };

  const validateCartsRef = useRef(() => {});
  useEffect(() => {
    validateCartsRef.current = validateCarts;
  });

  useEffect(() => {
    if (cartGroup?.carts && !cartResultsFetching && !cartsResultStale) {
      validateCartsRef.current();
    }
  }, [cartGroup, cartResultsFetching, cartsResultStale]);

  useEffect(() => {
    if (removeCartItemError || setCartItemError) {
      setHasError(!!setCartItemError);
      setErrorMessage(`${setCartItemError?.message ?? ''} ${removeCartItemError?.message ?? ''}`.trim());
    }
  }, [removeCartItemError, setCartItemError]);

  const handleNotify = useCallback(async () => {
    if (isAdmin && buyerID) {
      const result = await notify({
        input: {
          type: NotificationType.BuyerCartReady,
          profileID: buyerID,
        },
      });
      if (result.error) return result.error;
      handleRefresh();
    }
  }, [isAdmin, buyerID, notify, handleRefresh]);

  const handleSetCartItem = useDebouncedFunc(async (prevCarts, cart: CartInput & CartSeller, item: PartialCartItem) => {
    const result = await cartSetItem({
      cart: {
        deliveryDate: cart.deliveryDate,
        sellerUUID: cart.sellerUUID,
        shippingMethod: cart.shippingMethod,
        truckTypeUUID: cart.truckTypeUUID,
        onlyConsignment: false,
        buyerUUID: cartGroup?.buyerUUID ?? cart.buyerUUID,
      },
      item: {
        productUUID: item.productUUID,
        quantity: item.unitQuantity,
        ripeness: item.ripeness,
      },
    });

    if (result.error) {
      setHasError(true);
      setErrorMessage(result.error.message);
      setCarts(prevCarts);
    } else {
      logAnalytics('update_cart', {
        currency: 'ZAR',
        items: [
          {
            item_id: item.productUUID,
            quantity: item.unitQuantity,
          },
        ],
        path_location: location,
      });

      handleRefresh();
    }
  }, DEBOUNCE_TIME);

  const handleUpdate = useCallback(
    async (cart: CartInput & CartSeller, item: PartialCartItem) => {
      const prevCarts = structuredClone(carts);

      setCarts((prev) => {
        const existingCart = prev.find((c) => c.seller?.id?.toString() === cart.seller?.id?.toString());

        if (existingCart) {
          return prev.map((c) => {
            if (c.seller?.id === cart.seller?.id) {
              const isExistingItem = c.items.find((i) => i.productUUID.toString() === item.productUUID.toString());
              let updatedItems;

              if (isExistingItem) {
                updatedItems = c.items.map((i) =>
                  i.productUUID.toString() === item.productUUID.toString() ? item : i,
                );
              } else {
                updatedItems = [item, ...c.items];
              }

              return {
                ...c,
                items: updatedItems,
              };
            }
            return c;
          });
        }

        return [
          {
            seller: cart.seller,
            cartCases: cart.cartCases,
            items: [item],
          },
          ...prev,
        ];
      });

      setLocalVersion(new Date().getTime());
      handleSetCartItem(prevCarts, cart, item);
    },
    [carts, handleSetCartItem],
  );

  const handleRemoveRequest = useCallback(
    async (input: RemoveInput, prevCarts: CartItemSeller[]) => {
      try {
        const result = await cartRemove({ input });

        if (result.error) {
          setHasError(true);
          setErrorMessage(result.error.message);
          setCarts(prevCarts);
        } else {
          logAnalytics('remove_from_cart', {
            currency: 'ZAR',
            items: [{ item_id: input.productID }],
          });
          handleRefresh();
        }
      } catch (error) {
        setHasError(true);
        setErrorMessage('An unexpected error occurred.');
        setCarts(prevCarts);
      }
    },
    [cartRemove, handleRefresh],
  );

  const remove = useCallback(
    (sellerId: string, productUUID: string) => {
      if (!cartGroup) return;

      const prevCarts = structuredClone(carts);

      setCarts((prev) => {
        return prev
          .map((cart) => {
            if (cart.seller?.id !== sellerId) return cart;
            const newItems = cart.items.filter((item) => item.productUUID.toString() !== productUUID.toString());
            if (newItems.length === 0) return null;
            return {
              ...cart,
              items: newItems,
            };
          })
          .filter((cart) => cart !== null) as { seller: MarketSellerInfo; items: PartialCartItem[] }[];
      });
      setLocalVersion(new Date().getTime());
      handleRemoveRequest(
        {
          sellerID: sellerId,
          productID: productUUID,
          buyerID: cartGroup.buyerUUID,
        },
        prevCarts,
      );
    },
    [cartGroup, carts, handleRemoveRequest],
  );

  const checkout = useCallback(
    async (total: number, input: CartCheckoutInput) => {
      const result = await cartCheckout({
        input,
      });
      logAnalytics('purchase', {
        transaction_id: Date.now().toString(),
        currency: 'ZAR',
        value: total,
        items: carts,
      });
      handleRefresh();
      setCarts([]);
      return result;
    },
    [handleRefresh, cartCheckout, carts],
  );

  const value = useMemo(
    () => ({
      cartGroup,
      carts,
      deliveryDate,
      rebate: dataTotals?.cart?.rebate,
      rebateAndCartsFetching: cartTotalsResultsFetching,
      cartTotals: dataTotals?.cart?.cartTotals,
      isCartsQueryPaused,
      isFetching,
      checkout,
      clear: () => setCarts([]),
      notify: handleNotify,
      update: handleUpdate,
      remove,
      refresh: handleRefresh,
      setDeliveryDate,
    }),
    [
      cartGroup,
      carts,
      cartTotalsResultsFetching,
      deliveryDate,
      dataTotals?.cart,
      isFetching,
      isCartsQueryPaused,
      checkout,
      handleNotify,
      handleUpdate,
      handleRefresh,
      remove,
      setCarts,
      setDeliveryDate,
    ],
  );

  return (
    <Context.Provider value={value}>
      {children}
      <ErrorSnackbar isOpen={!!hasError} onClose={() => setHasError(false)}>
        {errorMessage}
      </ErrorSnackbar>
    </Context.Provider>
  );
};

export const useBuyersMarketCart = () => {
  const result = useContext(Context);
  if (!result) throw new Error('useBuyersMarketCart must be used within a BuyersCartProvider');
  return result;
};
